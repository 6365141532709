<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            Local Community
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div class="content" mat-dialog-content>
        <p>The TrackiPet Local Community is a network of likeminded people who live in the nearby area and can help each
            other with the search should their pets go missing using a simple notification and location system accessed
            through the TrackiPet app.</p>

        <p>Rules</p>

        <ul>
            <li>Only share relevant information - when notifying the group that your pet is lost, include only necessary
                information such as the pet's name, breed, and last known location. Avoid sharing personal or
                unnecessary details.</li>
            <li>Respect other members' privacy - do not share other members' phone numbers or contact information
                without their explicit permission.</li>
            <li>Communicate clearly and concisely - when providing updates on the search, keep messages brief and to the
                point. This will help other members quickly digest the information and take action if necessary.</li>
            <li>Be proactive in your search - while other members may assist in the search, it is ultimately your
                responsibility to locate and retrieve your lost pet. Use all available resources and take necessary
                actions, such as posting flyers or contacting local authorities.</li>
            <li>Stay positive and supportive - losing a pet can be a stressful and emotional experience. Members should
                offer support and encouragement to each other, and avoid any negative or discouraging comments.</li>
        </ul>

    </div>
</div>