import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { skin } from 'src/white-labels';

export const TEXT_INPUT =
    /^(?![\s-])[a-zA-Za-åa-ö-w-я-Z0-9-_\u0590-\u05FF\uFB1D-\uFB4F\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf].*[^ ]+$/;
// email validation RFC 5322 Standard
export const EMAIL_VALIDATOR_REG_EXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_VALIDATOR_REG_EXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$#_@!%*?&.]{6,24}$/;
export const TNT_PASSWORD_VALIDATOR_REG_EXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#_@!%*?&.])[a-zA-Z\d$#_@!%*?&.]{8,24}$/;
export const REX_EXP_UPPER_CASE = /^(?=.*[A-Z])/;
export const REX_EXP_LOWER_CASE = /^(?=.*[a-z])/;
export const REX_EXP_DIGIT = /^(?=.*[0-9])/;
export const NUMBERS_ONLY = /^\d+$/;
export const REX_EXP_SPECIAL_CHAR = /^(?=.*[$#_@!%*?&.])/;
export const TIME_VALIDATOR_REG_EXP = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const VERIFICATION_CODE_REX_EXP = /^[0-9]{4,4}$/;
export const VERIFICATION_CODE_FORGOT_PASSWORD_REX_EXP = /^[0-9]{5,5}$/;
export const URL_VALIDATION_PATTERN =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const API_PING_INTERVAL = 15000;
export const SUBACCOUNTS_LOCATION_PING_INTERVAL = 300000;
export const MIN_ZONE_SIZE = 150;
export const MIN_ROAD_DISTANCE = 300;
export const ONE_KM = 1000;
export const MAX_FILE_SIZE_LIMIT = 1000000;
export const MAX_DEVICES_FOR_ADDRESS = 10;
export const PHONE_STRING = /^\+\d{0,15}$/;

export const SOCIAL_NETWORKS_PROVIDERS = {
    google: 'google',
    facebook: 'facebook',
    apple: 'apple',
};

export const PLAN_TYPES = {
    prepaid: 'PREPAID',
    not_prepaid: 'NONCE',
    coupon: 'COUPON',
    paypal: 'PAYPAL',
};

export const FILTER_TYPES = {
    devices: 'DEVICES',
    zones: 'ZONES',
};

export const DEFAULT_LANGUAGE = 'en';

export const LANGUAGES = [
    { value: 'en', viewValue: 'English' },
    { value: 'ja', viewValue: '日本語' },
    { value: 'it', viewValue: 'Italian' },
    { value: 'pl', viewValue: 'Polish' },
    { value: 'pt', viewValue: 'Portuguese' },
    { value: 'ru', viewValue: 'Russian' },
    { value: 'es', viewValue: 'Spanish' },
    { value: 'de', viewValue: 'German' },
    { value: 'uk', viewValue: 'Ukrainian' },
    { value: 'fr', viewValue: 'French' },
    { value: 'tr', viewValue: 'Turkish' },
    { value: 'no', viewValue: 'Norwegian' },
    { value: 'he', viewValue: 'Hebrew' },
];

export const JAPANESE_LANGUAGE = 'ja';

export const LANGUAGES_TNT = [
    { value: 'en', viewValue: 'English' },
    { value: 'es', viewValue: 'Spanish' },
    { value: 'it', viewValue: 'Italian' },
    { value: 'pt', viewValue: 'Portuguese' },
    { value: 'el', viewValue: 'Greek' },
];

export const TNT_COUNTRIES_LIST = [
    { value: 'it', viewValue: 'Vodafone Italy' },
    { value: 'es', viewValue: 'Vodafone Spain' },
    { value: 'pt', viewValue: 'Vodafone Portugal' },
    { value: 'al', viewValue: 'Vodafone Albania' },
    { value: 'ie', viewValue: 'Vodafone Ireland' },
    { value: 'gr', viewValue: 'Vodafone Greece' },
    { value: 'uk', viewValue: 'Vodafone UK' },
];

export const LANGUAGES_MAMORI = [
    { value: 'en', viewValue: 'English' },
    { value: 'ja', viewValue: 'Japanese' },
];

export const OVERWATCH_USAGE = [
    { value: 'TRAVELER', title: 'TRAVELER' },
    { value: 'DRIVER_CAR', title: 'DRIVER_CAR' },
    { value: 'OTHER', title: 'OTHER' },
];

export const TRACKER_USAGE = [
    { value: 'CAR', title: 'CAR' },
    { value: 'VEHICLE', title: 'VEHICLE' },
    { value: 'PET', title: 'PET' },
    { value: 'HUMAN', title: 'HUMAN' },
    { value: 'ASSET', title: 'ASSET' },
    { value: 'BAG', title: 'BAG' },
    { value: 'KEYS', title: 'KEYS' },
    { value: 'WALLET', title: 'WALLET' },
    { value: 'BIKE', title: 'BIKE' },
    { value: 'SCOOTER', title: 'SCOOTER' },
    { value: 'BICYCLE', title: 'BICYCLE' },
    { value: 'DRONE', title: 'DRONE' },
];
export const RED_COLOR = '#e53935';
export const SNACKBAR_DURATION = 4000;
export const MAX_DEVICE_ZONES_ASSIGNMENTS = 5;
export const MAX_DEVICES_HISTORY_SELECT = 8;
export const MAX_DEVICES_EDIT_SELECT = 8;
export const FAST_TRACKING_TIMEOUT = 900; // 15 min
export const IDEAL_LOCK_RESPONSE_TIME = 45000; // 45 s

export const DEFAULT_PET_PHOTO = [
    'assets/images/pet-identification-photos/3.svg',
    'assets/images/pet-identification-photos/1.svg',
    'assets/images/pet-identification-photos/2.svg',
];

export enum ZoneType {
    RECTANGLE = 'rectangle',
    POLYGON = 'polygon',
    POLYLINE = 'polyline',
    DEVICE_GEOZONE = 'device_geozone',
    WIFI = 'wifi_geozone',
}

export enum Descendants {
    SUBACCOUNTS = 'Subaccounts',
    TRACKERS = 'Trackers',
}

export enum COMMON_METRIC_SYSTEM {
    IMPERIAL = 'IMPERIAL',
    METRIC = 'METRIC',
}

export enum METRIC_SYSTEM {
    MPH = 'mph',
    KPH = 'kph',
}

export enum METRIC_SPEED {
    KPH = 'KM / H',
    MPH = 'MI / H',
}

export enum StatusLabelType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export enum SleepModeRepeatType {
    NOT_REPEAT = 'NOT_REPEAT',
    REPEAT_EVERYDAY = 'REPEAT_EVERYDAY',
    REPEAT_CUSTOM = 'REPEAT_CUSTOM',
}

export enum LocationState {
    WAITING = 'waiting',
    TIMEOUT = 'timeout',
    SUCCESS = 'success',
    TRUE = 'true',
}

export enum DeviceStatus {
    INACTIVE = 'inactive',
    ACTIVE = 'active',
    EXPIRED = 'expired',
}

export enum Size {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export enum Gender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
}

export enum TrackingModeSettings {
    POWER_SAVING = 240,
    EVERYDAY = 60,
    OFF = 0,
    CUSTOM = -1,
    SECONDS = 5,
}

export enum USER_TYPES {
    MASTER_AND_SUB_ACCOUNTS = 1,
    ONLY_SUB_ACCOUNTS = 2,
    ONLY_MASTER_ACCOUNT = 3,
}

export enum USER_FEATURES_OPTIONS_TYPE {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    OPTIONAL = 'OPTIONAL',
}

export const HISTORY_LIMIT = 5000;

export const USER_FEATURES_LIST = ['social_login', 'pet_dashboard', 'qr_scanning', 'gql_location'];

export const TRAVEL_MODES = [
    { mode: 'DRIVING', icon: '#car', title: 'CAR', isActive: false },
    { mode: 'BICYCLING', icon: '#bike', title: 'BIKE', isActive: false },
    { mode: 'WALKING', icon: '#walk', title: 'WALK', isActive: false },
    { mode: 'TRANSIT', icon: '#transit', title: 'TRANSIT', isActive: false },
];

export const TRACKING_MODE_SETTINGS = [
    {
        title: 'POWER_SAVING',
        desc: 'LOCATION_UPDATES_EVERY_4_HOURS',
        value: TrackingModeSettings.POWER_SAVING,
        tracking_measurment: 'minutes',
    },
    {
        title: 'EVERYDAY',
        desc: 'LOCATION_UPDATES_EVERY_1_HOUR',
        value: TrackingModeSettings.EVERYDAY,
        tracking_measurment: 'minutes',
    },
    // {
    //     title: 'REALTIME',
    //     desc: 'LOCATION_UPDATES_EVERY_5_SEC',
    //     value: TrackingModeSettings.SECONDS,
    //     tracking_measurment: 'seconds',
    // },
    {
        title: 'OFF',
        desc: 'LOCATION_UPDATES_OFF',
        value: TrackingModeSettings.OFF,
    },
    {
        title: 'CUSTOM',
        desc: 'CUSTOMIZE_YOUR_MODE',
        value: TrackingModeSettings.CUSTOM,
    },
];

export const TRACKING_MODE_INTERVALS = [1, 5, 10, 15, 30, 45, 60, 120];
export const TRACKING_MODE_INTERVALS_TRACKIPET = [2, 5, 10, 15, 30, 45, 60, 120];

export enum DATE_FORMATS {
    DEFAULT = 'dd/MM/yyyy',
    EU = 'dd/MM/yyyy',
    US = 'MM/dd/yyyy',
    JP = 'yyyy/MM/dd',
}

export const OWL_DATE_FORMAT_PORVIDER = {
    provide: OWL_DATE_TIME_FORMATS,
    useValue: {
        parseInput: 'DD/MM/YYYY HH:mm',
        fullPickerInput: 'DD/MM/YYYY HH:mm',
        datePickerInput: 'DD/MM/YYYY',
        timePickerInput: 'HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const DATE_FORMAT_VALUES = [
    {
        value: 'DEFAULT',
        viewValue: 'Default',
    },
    {
        value: 'EU',
        viewValue: 'dd/mm/yyyy',
    },
    {
        value: 'US',
        viewValue: 'mm/dd/yyyy',
    },
    {
        value: 'JP',
        viewValue: 'yyyy/mm/dd',
    },
];

export const TIME_ZONES = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Algiers',
    'Africa/Bissau',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/El_Aaiun',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Khartoum',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis',
];

export const TRACKIMO_TYPE = [
    {
        icon_id: '9',
        device_name: 'Trackimo Plus',
        desc: 'CURVE_DESC',
        icon: '#curve',
        isSelected: true,
    },
];

export const DEVICES_TYPES = [
    {
        icon_id: '6',
        device_name: 'Mini',
        desc: 'MINI_DESC',
        icon: '#mini',
        isSelected: false,
    },
    {
        icon_id: '5',
        device_name: 'Guardian',
        desc: 'GUARDIAN_DESC',
        icon: '#guardian',
        isSelected: false,
    },
    {
        icon_id: '7',
        device_name: 'Travel',
        desc: 'TRAVEL_DESC',
        icon: '#travel',
        isSelected: false,
    },
    {
        icon_id: '3',
        device_name: skin.whiteLabel === 'TAGANDTRACK' ? 'Tag and Track' : 'Tracki Pro',
        desc: 'TRACKI_DESC',
        icon: '#tracki_pro',
        isSelected: false,
    },
    {
        icon_id: '2',
        device_name: 'Watch',
        desc: 'WATCH_DESC',
        icon: '#watch',
        isSelected: false,
    },
    {
        icon_id: '4',
        device_name: 'Universal',
        desc: 'UNIVERSAL_DESC',
        icon: '#universal',
        isSelected: false,
    },
    {
        icon_id: '9',
        device_name: 'Trackimo Plus',
        desc: 'CURVE_DESC',
        icon: '#curve',
        isSelected: false,
    },
    {
        icon_id: '12',
        device_name: 'E-Toll',
        desc: 'ETOLL_DESC',
        icon: '#eToll',
        isSelected: false,
    },
    {
        icon_id: '10',
        device_name: 'Male',
        desc: '',
        icon: '#boyAvatar',
        isSelected: false,
    },
    {
        icon_id: '11',
        device_name: 'Female',
        desc: '',
        icon: '#girlAvatar',
        isSelected: false,
    },
    {
        icon_id: '14',
        device_name: 'Pet',
        desc: '',
        icon: '#pawPrint',
        isSelected: false,
    },
];

export const ETOLL_DEVICE_TYPE = 'e-toll';
export const ETOLL_ICON_TYPE = 12;

export const DEFAULT_DEVICE_TYPE = DEVICES_TYPES[DEVICES_TYPES.length - 1];

export const FAQS = [
    {
        header: 'FAQS_HEADER_1',
        body: ['FAQS_BODY_1'],
    },
    {
        header: 'FAQS_HEADER_2',
        body: ['FAQS_BODY_2'],
    },
    {
        header: 'FAQS_HEADER_3',
        body: ['FAQS_BODY_3'],
    },
    {
        header: 'FAQS_HEADER_4',
        body: ['FAQS_BODY_4'],
    },
    {
        header: 'FAQS_HEADER_5',
        body: ['FAQS_BODY_5'],
    },
    {
        header: 'FAQS_HEADER_6',
        body: ['FAQS_BODY_6'],
    },
    {
        header: 'FAQS_HEADER_7',
        body: ['FAQS_BODY_7'],
    },
    {
        header: 'FAQS_HEADER_8',
        body: ['FAQS_BODY_8'],
    },
    {
        header: 'FAQS_HEADER_9',
        body: ['FAQS_BODY_9'],
    },
    {
        header: 'FAQS_HEADER_10',
        body: ['FAQS_BODY_10'],
    },
    {
        header: 'FAQS_HEADER_11',
        body: ['FAQS_BODY_11'],
    },
    {
        header: 'FAQS_HEADER_12',
        body: ['FAQS_BODY_12'],
    },
];

export const TNT_FAQS = [
    {
        header: 'faqHead1',
        body: ['faqPar1'],
    },
    {
        header: 'faqHead2',
        body: ['faqPar2'],
    },
    {
        header: 'faqHead3',
        body: ['faqPar3'],
    },
    {
        header: 'faqHead4',
        body: ['faqPar4'],
    },
    {
        header: 'faqHead5',
        body: ['faqPar5'],
    },
    {
        header: 'faqHead6',
        body: ['faqPar6'],
    },
    {
        header: 'faqHead7',
        body: ['faqPar7'],
    },
    {
        header: 'faqHead8',
        body: ['faqPar8'],
    },
    {
        header: 'faqHead9',
        body: ['faqPar9'],
    },
    {
        header: 'faqHead10',
        body: ['faqPar10'],
    },
    {
        header: 'faqHead11',
        body: ['faqPar11'],
    },
    {
        header: 'faqHead12',
        body: ['faqPar12'],
    },
    {
        header: 'faqHead13',
        body: ['faqPar13'],
    },
    {
        header: 'faqHead14',
        body: ['faqPar14'],
    },
    {
        header: 'faqHead15',
        body: ['faqPar15'],
    },
    {
        header: 'faqHead16',
        body: ['faqPar16_1', 'faqPar16_2', 'faqPar16_3'],
    },
    {
        header: 'faqHead17',
        body: ['faqPar17'],
    },
    {
        header: 'faqHead18',
        body: ['faqPar18_1'],
    },
    {
        header: 'faqHead19',
        body: ['faqPar19'],
    },
    {
        header: 'faqHead20',
        body: ['faqPar20'],
    },
    {
        header: 'faqHead21',
        body: ['faqPar21_1', 'faqPar21_2', 'faqPar21_3'],
    },
    {
        header: 'faqHead22',
        body: ['faqPar22'],
    },
    {
        header: 'faqHead23',
        body: ['faqPar23'],
    },
    {
        header: 'faqHead24',
        body: ['faqPar24'],
    },
    {
        header: 'faqHead25',
        body: ['faqPar25'],
    },
    {
        header: 'faqHead26',
        body: ['faqPar26'],
    },
    {
        header: 'faqHead27',
        body: ['faqPar27_1', 'faqPar27_2', 'faqPar27_3', 'faqPar27_4'],
    },
    {
        header: 'faqHead28',
        body: ['faqPar28'],
    },
    {
        header: 'faqHead29',
        body: ['faqPar29'],
    },
    {
        header: 'faqHead30',
        body: ['faqPar30'],
    },
    {
        header: 'faqHead31',
        body: ['faqPar31'],
    },
    {
        header: 'faqHead32',
        body: ['faqPar32'],
    },
    {
        header: 'faqHead34',
        body: ['faqPar34'],
    },
    {
        header: 'faqHead35',
        body: ['faqPar35'],
    },
    {
        header: 'faqHead36',
        body: ['faqPar36'],
    },
    {
        header: 'ACCESSING_THE_APPLICATION_QUESTION_1',
        body: ['ACCESSING_THE_APPLICATION_ANSWER_1'],
    },
    {
        header: 'ACCESSING_THE_APPLICATION_QUESTION_2',
        body: ['ACCESSING_THE_APPLICATION_ANSWER_2'],
    },
    {
        header: 'MAPS_MY_ZONES_QUESTION_1',
        body: ['MAPS_MY_ZONES_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_QUESTION_1',
        body: ['MY_TRACKER_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_LOCATION_HISTORY_QUESTION_1',
        body: ['MY_TRACKER_LOCATION_HISTORY_ANSWER_1'],
    },
    {
        header: 'MY_TRACKER_MAPS_QUESTION_1',
        body: ['MY_TRACKER_MAPS_ANSWER_1'],
    },
    {
        header: 'MY_ZONES_QUESTION_1',
        body: ['MY_ZONES_ANSWER_1'],
    },
    {
        header: 'MY_ZONES_QUESTION_2',
        body: ['MY_ZONES_ANSWER_2'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_1',
        body: ['NOTIFICATIONS_ANSWER_1'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_2',
        body: ['NOTIFICATIONS_ANSWER_2'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_3',
        body: ['NOTIFICATIONS_ANSWER_3'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_4',
        body: ['NOTIFICATIONS_ANSWER_4'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_5',
        body: ['NOTIFICATIONS_ANSWER_5'],
    },
    {
        header: 'NOTIFICATIONS_QUESTION_6',
        body: ['NOTIFICATIONS_ANSWER_6'],
    },
    {
        header: 'SHARING_TRACKER_INFORMATION_QUESTION_1',
        body: ['SHARING_TRACKER_INFORMATION_ANSWER_1'],
    },
    {
        header: 'CREATING_A_CONTACT_QUESTION_1',
        body: ['CREATING_A_CONTACT_ANSWER_1'],
    },
];

export const TNT_CONTACT_US = [
    {
        location: 'Vodafone Italy',
        label_1: 'CONTACTS_LABEL_1_ITA',
        label_2: 'CONTACTS_LABEL_2_ITA',
        label_3: 'CONTACTS_LABEL_3_ITA',
        label_4: 'CONTACTS_LABEL_4_ITA',
    },
    {
        location: 'Vodafone Spain',
        label_1: 'CONTACTS_LABEL_1_SPA',
        label_2: 'CONTACTS_LABEL_2_SPA',
        label_3: 'CONTACTS_LABEL_3_SPA',
        label_4: 'CONTACTS_LABEL_4_SPA',
    },

    {
        location: 'Vodafone Portugal',
        label_1: 'CONTACTS_LABEL_1_POR',
        label_2: 'CONTACTS_LABEL_2_POR',
        label_3: 'CONTACTS_LABEL_3_POR',
    },
    {
        location: 'Vodafone Albania',
        label_1: 'CONTACTS_LABEL_1_ALB',
        label_2: 'CONTACTS_LABEL_2_ALB',
        label_3: 'CONTACTS_LABEL_3_ALB',
        label_4: 'CONTACTS_LABEL_4_ALB',
    },
    {
        location: 'Vodafone Ireland',
        label_1: 'CONTACTS_LABEL_1_IRL',
    },
    {
        location: 'Vodafone Greece',
        label_1: 'CONTACTS_LABEL_1_GR',
        label_2: 'CONTACTS_LABEL_2_GR',
        label_3: 'CONTACTS_LABEL_3_GR',
        label_4: 'CONTACTS_LABEL_4_GR',
    },
    {
        location: 'Vodafone UK',
        label_1: 'CONTACTS_LABEL_1_UK',
    },
];

export const TNT_PRIVACY_POLICY = [
    {
        title: 'PP_CHANGES_POLICY',
        info: ['PP_CHANGES_POLICY_TEXT'],
    },
    {
        title: 'PP_APPLIES',
        info: ['PP_APPLIES_P1', 'PP_APPLIES_P2', 'PP_APPLIES_P3'],
    },
    {
        title: 'PP_OUR_PRINCIPLE',
        info: ['PP_OUR_PRINCIPLE_P1', 'PP_OUR_PRINCIPLE_P2'],
    },
    {
        title: 'PP_BASICS',
        info: ['PP_BASICS_INFO'],
    },
    {
        title: 'PP_SUPPLEMENTS',
        info: ['PP_SUPPLEMENTS_INFO'],
    },
    {
        title: 'PP_YOUR_RIGHTS',
        info: ['PP_YOUR_RIGHTS_INFO'],
    },
];

export const TNT_USER_GUIDE = [
    {
        title: 'INTRODUCTION',
        innerText: 'INTRODUCTION_INFO',
    },
    {
        title: 'ZONE_ALERTS',
        innerText: 'ZONE_ALERTS_INFO',
    },
    {
        title: 'MISSED_ALERTS',
        innerText: 'MISSED_ALERTS_INFO',
    },
    {
        title: 'GENERAL_GUIDANCE',
        innerText: 'GENERAL_GUIDANCE_INFO',
    },
];

export const MAIN_TOUR_STEPS: any[] = [
    {
        anchorId: 'devices',
        content: 'TUTS_DEVICES_TEXT',
        title: 'DEVICES',
        tourType: 'main',
    },
    {
        anchorId: 'zones',
        content: 'TUTS_ZONES_TEXT',
        title: 'ZONES',
        tourType: 'main',
    },
    {
        anchorId: 'notifications',
        content: 'TUTS_NOTIFS_TEXT',
        title: 'NOTIFICATIONS',
        tourType: 'main',
    },
    {
        anchorId: 'addDevice',
        content: 'TUTS_ADD_DEVICE_TEXT',
        title: 'ADD_DEVICE',
        isOptional: true,
        stepId: 'arrowDownCenter',
        tourType: 'main',
    },
];

export const DEVICE_PANEL_STEPS: any[] = [
    {
        anchorId: 'panelHistory',
        content: 'TUTS_HISTORY_TEXT',
        title: 'HISTORY',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
        tourType: 'devicePanel',
    },
    {
        anchorId: 'panelShare',
        content: 'TUTS_SHARE_TEXT',
        title: 'SHARE_LOCATION',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
        tourType: 'devicePanel',
    },
    {
        anchorId: 'panelSettings',
        content: 'TUTS_SETTINGS_TEXT',
        title: 'SETTINGS',
        stepId: 'arrowDownLeft',
        placement: { xPosition: 'before', yPosition: 'above' },
        tourType: 'devicePanel',
    },
    {
        anchorId: 'panelLocationType',
        content: 'TUTS_PRECISION_TEXT',
        title: 'PRECISION_MODE',
        stepId: 'precisionCircle',
        placement: { xPosition: 'before', yPosition: 'below' },
        tourType: 'devicePanel',
    },
];
