import { createAction, props } from '@ngrx/store';

const prefix = '[Map]';

const loadMap = createAction(`${prefix}`);
const mapLoadedSuccess = createAction(`${prefix} Success`, props<{ response: boolean }>());
const mapLoadedError = createAction(`${prefix} Error`, props<{ error: boolean }>());

const showNoLocationModal = createAction(`${prefix} Show No Location Modal`);
const hideNoLocationModal = createAction(`${prefix} Hide No Location Modal`);

const initNewZone = createAction(`${prefix} Init New Zone`);

const showLostPetLocation = createAction(`${prefix} Show Lost Pet Location`);
const hideLostPetLocation = createAction(`${prefix} Hide Lost Pet Location`);

export const MapActions = {
    loadMap,
    mapLoadedSuccess,
    mapLoadedError,
    initNewZone,
    showNoLocationModal,
    hideNoLocationModal,
    showLostPetLocation,
    hideLostPetLocation,
};
