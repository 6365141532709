<div class="dialog-wrapper" rtl>
    <div mat-dialog-title>
        <h1 class="title">Show/Hide future features</h1>
    </div>
    <div class="content" mat-dialog-content>

        <mat-form-field class="form-field" appearance="outline">
            <mat-label>White label</mat-label>
            <mat-select matNativeControl [(value)]="selectedWhiteLabel">
                <mat-option [value]="'MAMORI'" rtl>MAMORI</mat-option>
                <mat-option [value]="'MYTRACKI'" rtl>MYTRACKI</mat-option>
                <mat-option [value]="'TRACKIPET'" rtl>TRACKIPET</mat-option>
                <mat-option [value]="'TRACKIMO'" rtl>TRACKIMO</mat-option>
                <mat-option [value]="'FIDELIDADE'" rtl>FIDELIDADE</mat-option>
                <mat-option [value]="'PETLOC8'" rtl>PETLOC8</mat-option>
                <mat-option [value]="'MYLOC8'" rtl>MYLOC8</mat-option>
            </mat-select>
        </mat-form-field>


        <section class="checkbox-section" [formGroup]="featuresForm" *ngIf="featuresData.length; else noDataFeature">
            <mat-radio-group class="scope-options">
                <mat-checkbox *ngFor="let option of featuresData" class="form-field checkbox primary" color="primary"
                    formControlName="{{ option.featureName }}">
                    {{ option.featureName | translate }}
                </mat-checkbox>
            </mat-radio-group>
        </section>
        <ng-template #noDataFeature>
            <div class="no-data">No data features available for now...</div>
        </ng-template>
    </div>
</div>

<div mat-dialog-actions>
    <div class="nav-container">
        <button class="btn basic" (click)="close()" mat-flat-button>
            {{ 'CANCEL' | translate }}
        </button>
        <button class="btn primary" (click)="save(featuresForm.value)" mat-flat-button>
            {{ 'SAVE' | translate }}
        </button>
    </div>
</div>