export const PETLOC8 = {
    title: 'PETLOC8',
    brandName: 'PetLoc8',
    primaryColor: '#0FB528',
    androidAppLink: '',
    iosAppLink: 'https://apps.apple.com/ua/app/petloc8/id6736832204',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '9092cd94-a728-47b7-86da-e15c9a3d4cdb',
    clientSecret: '9f540cd42ec8d3bc452ce39cdd3d6de4',
    storeLink: 'https://petloc8.com/',
};
