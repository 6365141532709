export const MYLOC8 = {
    title: 'MYLOC8',
    brandName: 'myloc8',
    primaryColor: '#1181ef',
    androidAppLink: '',
    iosAppLink: '',
    localizeLink: 'https://trackimo-web-plus-translations.s3.us-west-2.amazonaws.com/locale/',
    clientId: '9092cd94-a728-47b7-86da-e15c9a3d4cdb',
    clientSecret: '9f540cd42ec8d3bc452ce39cdd3d6de4',
};
