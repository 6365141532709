import { environment } from 'src/environments/environment';

import { TRACKIMO } from './TRACKIMO';
import { TAGANDTRACK } from './TAGANDTRACK';
import { MAMORI } from './MAMORI';
import { MYTRACKI } from './MYTRACKI';
import { TRACKIPET } from './TRACKIPET';
import { WATCHINU } from './WATCHINU';
import { TRACKI } from './TRACKI';
import { FIDELIDADE } from './FIDELIDADE';
import { PETLOC8 } from './PETLOC8';
import { MYLOC8 } from './MYLOC8';

const whitelabelList = {
    TRACKIMO: TRACKIMO,
    MAMORI: MAMORI,
    MYTRACKI: MYTRACKI,
    TRACKIPET: TRACKIPET,
    WATCHINU: WATCHINU,
    TRACKI: TRACKI,
    FIDELIDADE: FIDELIDADE,
    PETLOC8: PETLOC8,
    MYLOC8: MYLOC8,
};

let skinConfig;
const whitelabel = localStorage.getItem('whitelabel');

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as any),
});
if (params['whitelabel']) {
    skinConfig = whitelabelList[params['whitelabel']];
} else if (whitelabel && whitelabelList[whitelabel]) {
    skinConfig = whitelabelList[whitelabel];
} else {
    if (window.location.hostname.indexOf('mamori') > -1) {
        skinConfig = MAMORI;
    } else if (window.location.hostname.indexOf('mytracki') > -1) {
        skinConfig = MYTRACKI;
    } else if (window.location.hostname.indexOf('trackipet') > -1) {
        skinConfig = TRACKIPET;
    } else if (window.location.hostname.indexOf('watchinu') > -1) {
        skinConfig = WATCHINU;
    } else if (window.location.hostname.indexOf('fidelidade') > -1) {
        skinConfig = FIDELIDADE;
    } else if (window.location.hostname.indexOf('petloc8') > -1) {
        skinConfig = PETLOC8;
    } else if (window.location.hostname.indexOf('myloc8') > -1) {
        skinConfig = MYLOC8;
    } else if (environment.whiteLabel === 'TAGANDTRACK') {
        skinConfig = TAGANDTRACK;
    } else {
        skinConfig = TRACKIMO;
    }
}

export const skin = {
    whiteLabel: skinConfig.title,
    brandName: skinConfig.brandName,
    primaryColor: skinConfig.primaryColor,
    androidAppLink: skinConfig.androidAppLink,
    iosAppLink: skinConfig.iosAppLink,
    localizeLink: skinConfig.localizeLink,
    clientId: skinConfig.clientId,
    clientSecret: skinConfig.clientSecret,
    storeLink: skinConfig.storeLink,
};
