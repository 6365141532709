import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    API_VERSION_1,
    API_VERSION_2,
    API_VERSION_3,
    API_VERSION_4,
    API_VERSION_5,
    API_VERSION_6,
    BASE_URL,
} from '../../constants/api.constants';
import {
    ILogin,
    IAuthLogin,
    ISigupVerify,
    IUser,
    IDeviceShort,
    IZone,
    IDeviceHistory,
    IPlan,
    ISigupResendLink,
    IResetPassword,
    IShareDeviceLocationRequest,
    IShareDeviceLocationResponse,
    IBeep,
    IDeviceSettings,
    IRenewPlan,
    IDeviceByToken,
    IResetPasswordMatch,
    IContact,
    IContactPayload,
    IContactAssignments,
    IDeviceLocationEvent,
    IUserChangePassPayload,
    ISMSOptions,
    ISubscription,
    IDeviceLeicaStatus,
    IEventsResponse,
    ISocialUserData,
    IDeviceUsage,
    IPetInfo,
    IPreferences,
    ISensorInfo,
    IDevicesCount,
    IDeviceFull,
    IFeatures,
    IFPISettings,
    IPendingFields,
    IDeviceActivePlans,
    IRings,
    IJoinedRing,
    IRingNotification,
    IInvitationLink,
    ILocation,
    IWifi,
    IEmailCheck,
    ILostPetInfo,
    IPetProfileListItem,
    IPetProfile,
    ILocalCommunity,
    ICommunityMember,
} from '../../interfaces';
import { skin } from 'src/white-labels';
import { LocalStorageService } from '../local-storage.service';
import {
    DEFAULT_LANGUAGE,
    HISTORY_LIMIT,
    SOCIAL_NETWORKS_PROVIDERS,
    USER_TYPES,
} from '../../constants/common.constants';
import { IDescendant, IDescendantDeviceMove } from '../../interfaces/descendants.interface';
import { SortDirection } from '@angular/material/sort';
import { Md5 } from 'ts-md5';
import { format } from 'date-fns';
import { SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class ApiService {
    constructor(private http: HttpClient, private localStorage: LocalStorageService) {}

    login(requestData: ILogin): Observable<{}> {
        const requestURL =
            skin.whiteLabel === 'TAGANDTRACK'
                ? `${BASE_URL}/${API_VERSION_3}/user/login`
                : `${BASE_URL}/internal/${API_VERSION_2}/user/login`;

        return this.http.post(requestURL, requestData, {
            withCredentials: true,
        });
    }

    loginAuth(auth: IAuthLogin): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/oauth2/auth?client_id=${auth.client_id}&redirect_uri=${auth.redirect_uri}&response_type=${auth.response_type}&scope=${auth.scope}`,
            { withCredentials: true },
        );
    }

    loginToken(tokenReq: any): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/oauth2/token`, tokenReq);
    }

    logout(): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/user/logout`, null, {
            withCredentials: true,
        });
    }

    emailCheck(emailData: IEmailCheck): Observable<any> {
        return this.http.post(`${BASE_URL}/${API_VERSION_1}/user/email/check`, emailData);
    }

    // socialLogin(userData, provider) {
    //     return this.http.post(`${BASE_URL}/${API_VERSION_2}/social/login/${provider}`, userData);
    // }

    createAccount(user: any): Observable<{}> {
        const requestURL =
            skin.whiteLabel === 'TAGANDTRACK'
                ? `${BASE_URL}/${API_VERSION_3}/user/signupWithUserIcon`
                : `${BASE_URL}/internal/${API_VERSION_2}/user/signupWithUserIcon`;

        return this.http.post(requestURL, user);
    }

    resendLink(resendData: ISigupResendLink): Observable<{}> {
        const apiVersion = skin.whiteLabel === 'TAGANDTRACK' ? API_VERSION_3 : `internal/${API_VERSION_2}`;
        return this.http.post(`${BASE_URL}/${apiVersion}/user/resend`, resendData);
    }

    resetPassword(data: IResetPassword): Observable<{}> {
        const requestURL = `${BASE_URL}/internal/${API_VERSION_1}/user/mobile/password/reset?whitelabel=${skin.whiteLabel}`;

        if (data.password) {
            return this.http.put(`${requestURL}`, data);
        }
        return this.http.post(`${requestURL}`, data);
    }

    resetPasswordMatch(data: IResetPasswordMatch): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/user/mobile/password/reset/match?whitelabel=${skin.whiteLabel}`,
            data,
        );
    }

    changePassword(payload: IUserChangePassPayload): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/user/password`, payload);
    }

    verifyAccount(verificationData: ISigupVerify): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/user/verify`, verificationData);
    }

    getUser(): Observable<IUser> {
        return this.http.get<IUser>(
            `${BASE_URL}/${API_VERSION_3}/user?fetch_account_features=true&whitelabel=${skin.whiteLabel}`,
        );
    }

    setUserPreferences(accountID: number, preferences): Observable<IUser> {
        return this.http.put<IUser>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/update`, preferences);
    }

    getDescendants(accountID: number): Observable<IDescendant> {
        return this.http.get<IDescendant>(
            `${BASE_URL}/${API_VERSION_5}/accounts/${accountID}/descendants?ignore_devices=true`,
        );
    }

    getDescendantsOnly(accountID: number): Observable<IDescendant[]> {
        return this.http.get<IDescendant[]>(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/descendants_only?direct_sub_accounts=true&limit=1000`,
        );
    }

    getDescendantsDevices(
        accountID: number,
        page: number,
        accounts_type: number,
    ): Observable<IDeviceShort[]> {
        return this.http.get<IDeviceShort[]>(
            `${BASE_URL}/${API_VERSION_5}/accounts/${accountID}/descendants/devices?page=${page}&limit=2000&account_sub_account_action=${accounts_type}`,
        );
    }

    moveDescendantDevice(
        accountID: number,
        deviceID: number,
        SRCAccountID: number,
        DSTAccountEmail: string,
    ): Observable<IDescendantDeviceMove> {
        return this.http.post<IDescendantDeviceMove>(
            `${BASE_URL}/internal/${API_VERSION_1}/accounts/${accountID}/move`,
            {
                src_account_id: SRCAccountID,
                dst_account_email: DSTAccountEmail,
                device_id: deviceID,
            },
        );
    }

    removeDescendant(accountID: number, email: string): Observable<IDescendant> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: [email],
        };
        return this.http.delete<IDescendant>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/parent`,
            options,
        );
    }

    getDevicesShort(accountID: number): Observable<IDeviceShort[]> {
        return this.http.get<IDeviceShort[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/list/details/short`,
        );
    }

    getDevicesShortWithParams(accountID: number, page: number): Observable<IDeviceShort[]> {
        return this.http.get<IDeviceShort[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/list/details/short?page=${page}&size=100`,
        );
    }

    getDeviceDetails(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/devices/${deviceID}/details/full`,
        );
    }

    getDeviceSensorsDetails(accountID: number, deviceID: number): Observable<ISensorInfo[]> {
        return this.http.get<ISensorInfo[]>(
            `${BASE_URL}/${API_VERSION_6}/accounts/${accountID}/devices/${deviceID}/sensors_last_readings`,
        );
    }

    getDeviceDetailsByToken(token: string): Observable<IDeviceByToken> {
        return this.http.get<IDeviceByToken>(`${BASE_URL}/${API_VERSION_3}/public/devices/${token}`);
    }

    sendBeepToDevice(peyload: IBeep, accountID: number): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/ops/beep`, peyload);
    }

    getDeviceContactAssignments(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/contacts`,
        );
    }

    setDeviceContactAssignments(
        accountID: number,
        deviceID: number,
        dataRequest: IContactAssignments,
    ): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/contacts`,
            dataRequest,
        );
    }

    // Live tracking
    startLiveTracking(
        accountID: number,
        fast_tracking_duration_on: number,
        fast_tracking_frequency: number,
        deviceIDs: number[],
    ): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/ops/fast_tracking`, {
            fast_tracking_duration_on,
            fast_tracking_frequency,
            device_ids: deviceIDs,
        });
    }
    stopLiveTracking(accountID: number): Observable<{}> {
        return this.http.delete(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/ops/fast_tracking?account_sub_account_action=1`,
        );
    }

    getZones(accountID: number): Observable<IZone[]> {
        return this.http.get<IZone[]>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozones?showPolyZone=true`,
        );
    }

    getZoneByID(accountID: number, geozoneID: number): Observable<IZone> {
        return this.http.get<IZone>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozoneWithDevices/${geozoneID}`,
        );
    }

    createNewZone(accountID: number, zone: IZone): Observable<IZone> {
        return this.http.post<IZone>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozoneWithDevices`,
            zone,
        );
    }

    updateZone(accountID: number, zone: IZone): Observable<IZone> {
        return this.http.put<IZone>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozoneWithDevices/${zone.id}`,
            zone,
        );
    }

    removeZone(accountID: number, zoneID: number): Observable<IZone> {
        return this.http.delete<IZone>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozoneWithDevices/${zoneID}`,
        );
    }

    getWifiNetworksByDeviceId(accountID: number, deviceID: number): Observable<IWifi> {
        return this.http.get<IWifi>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/device/${deviceID}/wifiNetworks`,
            {},
        );
    }

    putWifiNetworksByDeviceId(accountID: number, deviceID: number): Observable<IWifi> {
        return this.http.put<IWifi>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/device/${deviceID}/wifiNetworks`,
            {},
        );
    }

    getAssigedZones(accountID: number, deviceID: number): Observable<IZone[]> {
        return this.http.get<IZone[]>(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/assigned/geozones/${deviceID}?showPolyZone=true`,
        );
    }

    assignDevice(accountID: number, zoneID: number, deviceID: number): Observable<{}> {
        return this.http.put(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozones/assign/${zoneID}`,
            [deviceID],
        );
    }

    unassignDevice(accountID: number, zoneID: number, deviceID: number): Observable<{}> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: [deviceID],
        };

        return this.http.delete(
            `server-geozone/${BASE_URL}/${API_VERSION_2}/${accountID}/geozones/unassign/${zoneID}`,
            options,
        );
    }

    getNotReadEvents(accountID: number): Observable<IEventsResponse> {
        return this.http.get<IEventsResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events/search/count?alarm_types=SOS`,
        );
    }

    getCSVEvents(accountID: number, deviceIDs: number[]) {
        return this.http.post<{ file_url: string }>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/eventsSearchCsvExport`,
            { device_ids: deviceIDs },
        );
    }

    getEventsWithTypes(
        accountID: number,
        alarmType: string,
        sort: string,
        order: SortDirection,
        page: number,
        search: string,
    ): Observable<any> {
        return this.http.get<any>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events?alarm_types=${alarmType}&sort=${sort}&order=${order}&page=${
                page + 1
            }&search=${search}`,
            { observe: 'response' as 'body' },
        );
    }

    getNotReadSOSEventsByDeviceID(accountID: number, deviceID: number): Observable<IEventsResponse> {
        return this.http.post<IEventsResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events/search?alarm_types=SOS&read_status=unread`,
            { device_ids: [deviceID] },
        );
    }

    getDeviceHistory(
        accountID: number,
        deviceID: number,
        from: number,
        to: number,
        types: number[],
        rsfEnabled: boolean,
    ): Observable<IDeviceHistory[]> {
        return this.http.get<IDeviceHistory[]>(
            `${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/location_event/history/details?rsf_enabled=${rsfEnabled}&from=${from}&to=${to}&limit=${HISTORY_LIMIT}&page=1&types=${types}&error_when_limit_crossed=true`,
        );
    }

    sendToEmalHistoryCSV(
        accountID: number,
        devices: number[],
        from: number,
        to: number,
        types: number[],
        rsfEnabled: boolean,
    ) {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/location_event/history/send_to_email?rsf_enabled=${rsfEnabled}&from=${from}&to=${to}&types=${types}&device_ids=${devices}`,
        );
    }

    deleteDeviceLocationHistory(
        accountID: number,
        deviceID: number,
        from: number,
        to: number,
    ): Observable<{}> {
        return this.http.delete(
            // `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/locationHistory?from=${from}&to=${to}`,
            `${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/location_event/history/details?from=${from}&to=${to}`,
        );
    }

    valideteDeviceAssignments(deviceID: number, imei: number): Observable<{ assigned: string }> {
        return this.http.get<{ assigned: string }>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/${deviceID}/assigned?challenge=${imei}`,
        );
    }

    valideteDeviceAssignmentsEtoll(deviceID: number, imei: number): Observable<{ assigned: boolean }> {
        return this.http.get<{ assigned: boolean }>(
            `${BASE_URL}/internal/${API_VERSION_3}/devices/${0}/assigned?challenge=${imei}`,
        );
    }

    getDevicePlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(`${BASE_URL}/internal/${API_VERSION_2}/devices/${deviceID}/plans_group`);
    }

    setDeviceToUser(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/activate/prepaid`,
            {},
        );
    }

    setDeviceToUserByStripe(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.post(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/activate/prepaid`,
            {},
        );
    }

    updateDeviceName(accountID: number, deviceID: number, name: string): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/name`, {
            name,
        });
    }

    setDeviceUsage(accountID: number, deviceID: number, usageInfo: string): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/usage/info?usageInfo=${usageInfo}`,
            null,
        );
    }

    setPetInfo(deviceUsageDTO: IDeviceUsage): Observable<{}> {
        return this.http.post(`pets/api/${API_VERSION_1}/pet`, deviceUsageDTO);
    }

    getPetInfo(deviceID): Observable<{}> {
        return this.http.get(`pets/api/${API_VERSION_1}/pet/deviceId/${deviceID}`);
    }

    shareDeviceLink(
        accountID: number,
        deviceID: number,
        duration: number,
    ): Observable<IShareDeviceLocationResponse> {
        return this.http.post<IShareDeviceLocationResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/share`,
            { duration },
        );
    }

    shareDevicesLink(
        accountID: number,
        duration: number,
        deviceIds: number[],
    ): Observable<IShareDeviceLocationResponse> {
        return this.http.post<IShareDeviceLocationResponse>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/share`,
            { deviceIds, duration },
        );
    }

    deactivateShareLink(accountID: number, deviceID: number): Observable<{}> {
        return this.http.delete(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/share`,
        );
    }

    deactivateShareLinkForCoupleDevices(accountID: number, devicesID: number[]): Observable<{}> {
        return this.http.delete(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/share?device_ids=${devicesID}`,
        );
    }

    updateDeviceImage(accountID: number, deviceID: number, imageToUpload: File): Observable<{}> {
        const formData: FormData = new FormData();
        formData.append('file', imageToUpload);

        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/icon`,
            formData,
        );
    }

    updateDeviceIcon(accountID: number, deviceID: number, iconId: number): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/icon`, {
            icon_id: iconId,
        });
    }

    updateUserPhoto(accountID: number, imageToUpload: File): Observable<{}> {
        const formData: FormData = new FormData();
        formData.append('file', imageToUpload);

        return this.http.post(`${BASE_URL}/${API_VERSION_3}/user/${accountID}/icon`, formData);
    }

    getDevicesLocations(accountID: number, accounts_type: number): Observable<IDeviceLocationEvent[]> {
        return this.http.get<IDeviceLocationEvent[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/locations?comm_stat=1&limit=2500&page=1&account_sub_account_action=${accounts_type}&fetch_is_fast_tracking_enabled=true`,
        );
    }

    getDeviceLocation(accountID: number, deviceID: number): Observable<{}> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http.get(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/locations/filter?comm_stat=1&device_ids=${deviceID}&fetch_is_fast_tracking_enabled=true`,
            { headers, responseType: 'text' },
        );
    }

    triggerDevicesLocations(accountID: number, devicesIDs: number[]): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/ops/getLocation`, {
            devices: devicesIDs,
            forceGpsRead: true,
            sendGsmBeforeLock: true,
        });
    }

    setDeviceSettings(accountID: number, deviceID: number, settings: IDeviceSettings): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/settings`,
            settings,
        );
    }

    getDeviceSettings(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/settings`,
        );
    }

    getRenewalPlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(
            `${BASE_URL}/internal/${API_VERSION_2}/devices/${deviceID}/default_renewal_plans_group`,
        );
    }

    getBrainTreeDropInToken(accountID: number, deviceID: number, planID: number): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

        return this.http.get(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/paypal/token?device_id=${deviceID}&plan_id=${planID}`,
            { headers, responseType: 'text' },
        );
    }

    setDeviceToTNTUser(accountID: number, deviceID: number, code: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_2}/accounts/${accountID}/devices/${deviceID}/activate/prepaid?challenge=${code}`,
            { code },
        );
    }

    updateNotificationStatus(accountID: number, eventID: number[] | number): Observable<{}> {
        return this.http.put(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events`, {
            eventIds: Array.isArray(eventID) ? eventID : [eventID],
            priority: 'NONE',
            read: true,
        });
    }

    deleteAllNotifications(accountID: number): Observable<{}> {
        return this.http.delete<IZone>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/events`);
    }

    getContacts(accountID: number): Observable<IContact[]> {
        return this.http.get<IContact[]>(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts`);
    }

    addContact(accountID: number, payload: IContactPayload): Observable<IContact> {
        return this.http.post<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts`,
            payload,
        );
    }

    deleteContact(accountID: number, contactId: number): Observable<{}> {
        return this.http.delete<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts/${contactId}`,
        );
    }

    updateContact(accountID: number, contactId: number, payload: IContactPayload): Observable<{}> {
        return this.http.put<IContact>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/contacts/${contactId}`,
            payload,
        );
    }

    getAddress(latitude: number, longitude: number): Observable<string> {
        let language = this.localStorage.getItem('language');

        const dt = new Date();
        const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

        return this.http.get(`/geolocation/${BASE_URL}/${API_VERSION_1}/geolocation/getaddress`, {
            responseType: 'text',
            headers: {
                API_Key: Md5.hashStr(`${latitude}_${longitude}_${format(dtDateOnly, 'ddMMyyyy')}`),
            },
            params: {
                latitude: latitude,
                longitude: longitude,
                language: language ? language : DEFAULT_LANGUAGE,
            },
        });
    }

    getAddressByLocationIQ(
        latitude: number,
        longitude: number,
        language: string,
        isDeviceListPage?: boolean,
    ): Observable<string> {
        return this.http
            .get(
                `https://us1.locationiq.com/v1/reverse.php?key=a95b22fef8c52c&lat=${latitude}&lon=${longitude}&format=json&accept-language=${language}&normalizeaddress=1`,
            )
            .pipe(
                map((resonse: any) => {
                    if (isDeviceListPage) {
                        const address = resonse.address;
                        return `${address.road ? address.road : ''} ${
                            address.house_number ? address.house_number + ',' : ''
                        } ${address.city}, ${address.country}`;
                    }
                    return resonse['display_name'];
                }),
            );
    }

    getSmsOptions(countryCode: number): Observable<ISMSOptions[]> {
        return this.http.get<ISMSOptions[]>(`${BASE_URL}/${API_VERSION_3}/country/${countryCode}/smsoptions`);
    }

    activateSmsPlanBraintree(accountID: number, deviceID: number, payload: any): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/sms`,
            payload,
        );
    }

    activatePlan(accountID: number, deviceID: number, payload: any): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/activation`,
            payload,
        );
    }

    activateByCoupon(accountID: number, deviceID: number, coupon: string): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/activate/coupon`,
            { coupon: coupon },
        );
    }

    addNewApp(payload: any): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients`, payload);
    }

    getClients(): Observable<{}> {
        return this.http.get(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients`);
    }

    deleteClient(clientID: number): Observable<{}> {
        return this.http.delete(`${BASE_URL}/internal/${API_VERSION_1}/oauth2/clients/${clientID}`);
    }

    getSubscriptions(
        accountID: number,
        page: number,
        limit: number,
        accounts_type: number,
        searchInfo: string,
    ): Observable<ISubscription[]> {
        return this.http.get<ISubscription[]>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/details/graphql?requireDataList=features,device_info,device_details_plan,extra_param:device_status;icon_url;icon_id;account_id;account_email&limit=${limit}&page=${page}&search=${searchInfo}&account_sub_account_action=${accounts_type}`,
            { observe: 'response' as 'body' },
        );
    }

    getAccountSettings(accountID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}?fetch_fast_tracking_status=true`,
        );
    }

    setPreciseLocation(accountID: number, payload): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/internal/${API_VERSION_1}/accounts/${accountID}/accurate?setAccurate=${payload}`,
            null,
        );
    }

    addSubAccountInvitation(accountID: number, payload): Observable<{}> {
        return this.http.post(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/parentrequest`, payload);
    }

    addSubAccountNewUser(payload): Observable<{}> {
        return this.http.post(`${BASE_URL}/internal/${API_VERSION_1}/accounts/newsub`, payload);
    }

    checkLeicaStatus(accountID: number, deviceID: number): Observable<IDeviceLeicaStatus> {
        return this.http.get<IDeviceLeicaStatus>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/leica/status`,
        );
    }

    updateLeicaStatus(accountID: number, deviceID: number, payload): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/leica/status`,
            payload,
        );
    }

    checkSomitomoStatus(accountID: number, deviceID: number): Observable<IDeviceLeicaStatus> {
        return this.http.get<IDeviceLeicaStatus>(
            `${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/devices/${deviceID}/somitomo/status`,
        );
    }

    triggerStatusUpdate(accountID: number, deviceID: number): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/sendStatusMessage/${deviceID}`,
            {},
        );
    }

    updateSomitomoStatus(accountID: number, deviceID: number, payload): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/${deviceID}/somitomo/status`,
            payload,
        );
    }

    renewPlan(accountID: number, deviceID: number, dto: IRenewPlan): Observable<{}> {
        return this.http.post<IShareDeviceLocationResponse>(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/paypal/renew`,
            dto,
        );
    }

    renewPrepaidPlan(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/activate/prepaid`,
            {},
        );
    }

    renewPlanByCoupon(accountID: number, deviceID: number, coupon: string): Observable<{}> {
        return this.http.put(
            `${BASE_URL}/internal/${API_VERSION_1}/activation/accounts/${accountID}/devices/${deviceID}/renew/coupon`,
            { coupon: coupon },
        );
    }

    signInWithSocialNetwork(userData: ISocialUserData, socialNetwork: string): Observable<{}> {
        const apiVersion =
            socialNetwork === SOCIAL_NETWORKS_PROVIDERS.facebook ? API_VERSION_1 : API_VERSION_2;
        return this.http.post(`${BASE_URL}/${apiVersion}/social/login/${socialNetwork}`, userData);
    }

    deleteMyAccount(userEmail: string): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/accounts/deleteuseraccount?whitelabel=${skin.whiteLabel}`,
            {
                email: userEmail,
            },
        );
    }

    getDeviceSubscriptionDetails(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/details/graphql?device_ids=${deviceID}&requireDataList=all_active_plans, device_info, device_details_plan, sms_details, extra_param:device_status;icon_url;icon_id`,
        );
    }

    getActivityDataByDate(deviceID: number, startDate: number, endDate: number): Observable<IPetInfo> {
        return this.http.get<IPetInfo>(
            `/activity/${BASE_URL}/${API_VERSION_1}/aggregation/day?deviceId=${deviceID}&startDateUtc=${startDate}&endDateUtc=${endDate}`,
        );
    }

    getDevicesSettings(accountID: number): Observable<IPreferences> {
        return this.http.get<IPreferences>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/settings`,
        );
    }

    setDevicesSettings(accountID: number, preferences: IPreferences): Observable<IPreferences> {
        return this.http.put<IPreferences>(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/settings`,
            preferences,
        );
    }
    getOverwatchStatus(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/details/graphql?requireDataList=features&device_ids=${deviceID}`,
        );
    }

    getMainAccountDevicesCount(accountID): Observable<IDevicesCount> {
        return this.http.get<IDevicesCount>(
            `${BASE_URL}/${API_VERSION_6}/accounts/${accountID}/devices/count?account_sub_account_action=${USER_TYPES.ONLY_MASTER_ACCOUNT}`,
        );
    }

    getSubaccountsDevicesCount(accountID): Observable<IDevicesCount> {
        return this.http.get<IDevicesCount>(
            `${BASE_URL}/${API_VERSION_6}/accounts/${accountID}/devices/count?account_sub_account_action=${USER_TYPES.MASTER_AND_SUB_ACCOUNTS}`,
        );
    }

    getDeviceFeatures(deviceID: number): Observable<IFeatures> {
        return this.http.get<IFeatures>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/features/deviceIds?deviceIds=${deviceID}`,
        );
    }
    /* FPI SETTINGS */
    getFPISettings(accountID: number, deviceID: number): Observable<IFPISettings> {
        return this.http.get<IFPISettings>(
            `/sos/${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/vehicle_details`,
        );
    }

    updateFPISettings(accountID: number, deviceID: number, payload): Observable<{}> {
        return this.http.put(
            `/sos/${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/vehicle_details`,
            payload,
        );
    }

    getDevicesFPIPendingFieldsCount(accountID: number, devicesID: number[]): Observable<IPendingFields[]> {
        return this.http.post<IPendingFields[]>(
            `/sos/${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/fpi/pending_fields`,
            devicesID,
        );
    }

    updateFPIUsage(accountID: number, deviceID: number, usageValue: string): Observable<{}> {
        return this.http.post(
            `/sos/${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/usage`,
            { usage: usageValue },
        );
    }

    updateFPIUsageFromSettings(
        accountID: number,
        deviceID: number,
        payload: { usage: string },
    ): Observable<{}> {
        return this.http.put(
            `/sos/${BASE_URL}/${API_VERSION_1}/accounts/${accountID}/devices/${deviceID}/usage`,
            payload,
        );
    }

    getStripeDevicePlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/${deviceID}/plan_group`,
        );
    }

    getStripeDeviceRenewalPlans(deviceID: number): Observable<IPlan> {
        return this.http.get<IPlan>(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/${deviceID}/renewal_plan_group`,
        );
    }

    getDevicePaymentGateway(deviceID: number): Observable<any> {
        return this.http.get<any>(
            `${BASE_URL}/internal/${API_VERSION_1}/devices/features/deviceIds?deviceIds=${deviceID}`,
        );
    }
    getStripeCredentials(deviceID: number): Observable<any> {
        return this.http.post<any>(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/devices/${deviceID}/stripe/token`,
            {},
        );
    }
    renewPlanWithStripe(accountID: number, deviceID: number, planID: number): Observable<{}> {
        return this.http.post(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/accounts/${accountID}/devices/${deviceID}/plan/${planID}/renew`,
            {},
        );
    }

    renewPlanWithActivationCode(accountID: number, deviceID: number, active_code: number): Observable<{}> {
        return this.http.post(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/accounts/${accountID}/devices/${deviceID}/active_code/${active_code}`,
            {},
        );
    }

    getRechargePlan(deviceID: number): Observable<IDeviceActivePlans[]> {
        return this.http.get<IDeviceActivePlans[]>(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/recharge/active_plan?device_ids=${deviceID}`,
        );
    }

    /* After STRIPE success associate Device with Account */
    associateDeviceWithAccount(account_id: number, device_id: number, plan_id: number): Observable<{}> {
        return this.http.post(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/accounts/${account_id}/devices/${device_id}/plan/${plan_id}/activate`,
            {},
        );
    }
    /* After STRIPE success end */

    //@TODO Use when stable API will be done
    getDevicesPlansByIds(deviceIDs: number[]): Observable<IDeviceActivePlans[]> {
        return this.http.get<IDeviceActivePlans[]>(
            `/payment-service/${BASE_URL}/${API_VERSION_2}/activation/activePlan?device_ids=${deviceIDs}`,
        );
    }

    /* RINGS */
    getRings(): Observable<IRings> {
        return this.http.get<IRings>(`/pets/${BASE_URL}/${API_VERSION_1}/ring`);
    }

    getJoinedRings(): Observable<IJoinedRing[]> {
        return this.http.get<IJoinedRing[]>(`/pets/${BASE_URL}/${API_VERSION_1}/joined`);
    }

    getRingsNotifications(): Observable<IRingNotification[]> {
        return this.http.get<IRingNotification[]>(`/pets/${BASE_URL}/${API_VERSION_1}/notification`);
    }

    getRingsNotificationByID(ringNotificationId: number): Observable<IRingNotification> {
        return this.http.get<IRingNotification>(
            `/pets/${BASE_URL}/${API_VERSION_1}/notification/${ringNotificationId}`,
        );
    }

    readAllRingNotification(): Observable<null> {
        return this.http.put<null>(`/pets/${BASE_URL}/${API_VERSION_1}/notification/read`, null);
    }

    getInvitationLink(): Observable<IInvitationLink> {
        return this.http.get<IInvitationLink>(`/pets/${BASE_URL}/${API_VERSION_1}/invite`);
    }

    acceptInvitation(code: string): Observable<null> {
        return this.http.post<null>(`/pets/${BASE_URL}/${API_VERSION_1}/invite/accept?code=${code}`, {});
    }

    removeMember(accountID: number): Observable<null> {
        return this.http.delete<null>(`/pets/${BASE_URL}/${API_VERSION_1}/member?accountId=${accountID}`);
    }

    updateDeviceSharing(deviceId: number, isShare: boolean): Observable<null> {
        return this.http.put<null>(
            `/pets/${BASE_URL}/${API_VERSION_1}/device/share?deviceId=${deviceId}&share=${isShare}`,
            {},
        );
    }

    fetchRingLocations(ringID: number): Observable<ILocation[]> {
        return this.http.get<ILocation[]>(`/pets/${BASE_URL}/${API_VERSION_2}/location?ringId=${ringID}`);
    }

    setRingPermission(accountID: number, permission: string): Observable<null> {
        return this.http.put<null>(
            `/pets/${BASE_URL}/${API_VERSION_1}/permission?accountId=${accountID}&permission=${permission}`,
            {},
        );
    }

    // Lost Pet
    getLostpetInfo(code: string): Observable<ILostPetInfo> {
        return this.http.get<ILostPetInfo>(
            `/pets/${BASE_URL}/${API_VERSION_1}/share/lost-pet/details?code=${code}`,
        );
    }

    helpToSearch(code: string): Observable<null> {
        return this.http.post<null>(
            `/pets/${BASE_URL}/${API_VERSION_1}/share/lost-pet/help-search?code=${code}`,
            {},
        );
    }

    getOwnerPhone(code: string): Observable<{ ownerPhone: string }> {
        return this.http.get<{ ownerPhone: string }>(
            `/pets/${BASE_URL}/${API_VERSION_1}/share/lost-pet/owner-phone?code=${code}`,
        );
    }

    getLocalCommunity(): Observable<ILocalCommunity> {
        return this.http.get<ILocalCommunity>(`/pets/${BASE_URL}/${API_VERSION_1}/local/community/state`);
    }

    joinLocalCommunity(joinInfo) {
        return this.http.post<null>(`/pets/${BASE_URL}/${API_VERSION_1}/local/community`, joinInfo);
    }

    getLocalCommunityDetails() {
        return this.http.get<any>(`/pets/${BASE_URL}/${API_VERSION_1}/local/community`);
    }

    updateLocalCommunity(joinInfo) {
        return this.http.put<null>(`/pets/${BASE_URL}/${API_VERSION_1}/local/community`, joinInfo);
    }

    getCommunityGlobalLink(): Observable<IInvitationLink> {
        return this.http.get<IInvitationLink>(`/pets/${BASE_URL}/${API_VERSION_1}/global/link`);
    }

    leaveLocalCommunity() {
        return this.http.delete<null>(`/pets/${BASE_URL}/${API_VERSION_1}/local/community`);
    }

    leaveCommunity(inCommunityID: number) {
        return this.http.delete<null>(`/pets/${BASE_URL}/${API_VERSION_1}/ring/${inCommunityID}/leave`);
    }

    getCommunityDetails(page: number): Observable<ICommunityMember[]> {
        return this.http.get<ICommunityMember[]>(
            `/pets/${BASE_URL}/${API_VERSION_1}/local/community/details?page=${page}`,
        );
    }

    getPetsLostCenter(): Observable<any> {
        return this.http.get<any>(`/pets/${BASE_URL}/${API_VERSION_1}/lost/center`);
    }
    // End Lost Pet

    // Pet profile
    getListOfPetProfiles(): Observable<IPetProfileListItem[]> {
        return this.http.get<IPetProfileListItem[]>(`pets/${BASE_URL}/${API_VERSION_2}/pet/profile`);
    }

    getPetProfile(id: number): Observable<IPetProfile> {
        return this.http.get<IPetProfile>(`pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}`);
    }

    createPetProfile(formData): Observable<null> {
        return this.http.post<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile`, formData);
    }

    updatePetProfile(id: number, formData): Observable<null> {
        return this.http.put<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}`, formData);
    }

    updatePetProfileIcon(id: number, imageToUpload: any): Observable<null> {
        const formData: FormData = new FormData();
        formData.append('icon', imageToUpload);
        return this.http.put<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}/icon`, formData);
    }

    setPetProfileImage(id: number, imageToUpload: any): Observable<{ images: [] }> {
        const formData: FormData = new FormData();
        formData.append('image', imageToUpload);

        return this.http.post<{ images: [] }>(
            `/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}/image`,
            formData,
        );
    }

    unLinkPetProfileToDevice(id: number, formData): Observable<null> {
        return this.http.patch<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}`, formData);
    }

    linkPetProfileToDevice(id: number, formData): Observable<null> {
        return this.http.patch<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}`, formData);
    }

    deletePetProfileImage(id: number, index: number): Observable<{ images: [] }> {
        return this.http.delete<{ images: [] }>(
            `/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}/image?index=${index}`,
        );
    }

    deletePetProfile(id: number): Observable<null> {
        return this.http.delete<null>(`/pets/${BASE_URL}/${API_VERSION_2}/pet/profile/${id}`);
    }
    // End pet profile

    // FW update
    getDevicesFWStatus(accountID: number): Observable<{}> {
        return this.http.get(`${BASE_URL}/${API_VERSION_3}/accounts/${accountID}/devices/list/details/short`);
        // return this.http.get(`${BASE_URL}/${API_VERSION_4}/accounts/${accountID}/devices/details`);
    }

    getFWchanges(accountID: number, deviceID: number): Observable<{}> {
        return this.http.get(
            `${BASE_URL}/${API_VERSION_3}/firmware/accounts/${accountID}/devices/${deviceID}/fw_changes`,
        );
    }

    enableFotaForDevice(accountID: number, deviceID: number, wifiInfo): Observable<{}> {
        return this.http.post(
            `${BASE_URL}/${API_VERSION_3}/firmware/accounts/${accountID}/devices/${deviceID}/wifi`,
            wifiInfo,
        );
    }

    getFotaLogs(accountID: number): Observable<{}> {
        return this.http.get(`${BASE_URL}/${API_VERSION_3}/firmware/accounts/${accountID}/logs`);
    }

    // {{baseUrl}}/api/v3/firmware/accounts/134518/devices/1122322/wifi
    // /api/v3/firmware/accounts/252977/devices/601009334/fw_changes
    // End FW update
}
