import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-communtie-info',
    templateUrl: './communtie-info.component.html',
    styleUrls: ['./communtie-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommuntieInfoComponent {
    constructor(
        public dialogRef: MatDialogRef<CommuntieInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public dialog: MatDialog,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }
}
