import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { distinctUntilChanged, forkJoin, merge, Observable, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
    combineLatestWith,
    filter,
    debounceTime,
    exhaustMap,
} from 'rxjs/operators';
import { DevicesActions } from '../actions/devices.actions';
import { MapActions } from '../actions/map.actions';
import { StatusLabelActions } from '../actions/status-label.actions';
import { ZonesActions } from '../actions/zones.actions';
import { StatusLabelType, USER_TYPES } from '../constants/common.constants';
import {
    selectDevicesList,
    selectDevicesTotalCount,
    selectSectedDevice,
} from '../selectors/devices.selectors';
import { selectUser } from '../selectors/user.selector';
import { ApiService, GoogleMapService, LocalStorageService } from '../services';
import { IAppState } from '../state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { AddDeviceService } from '../../dashboard/add-device-form/add-device.service';
import { SnackbarService } from '../services/snackbar.service';
import { IDeviceFull, IEventsResponse, ILatLng, IUser, IZone } from '../interfaces';
import { ContactsActions } from '../actions/contacts.actions';
import { SoundService } from '../services/sound.service';
import { selectSubaccountsDevices } from '../selectors/descendants.selectors';
import { UpdatePlanDialogComponent } from 'src/app/dialogs/update-plan-dialog/update-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { EventsActions } from '../actions/events.actions';
import { PAYMENT_TYPE } from '../constants';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DevicesEffects {
    getDevicesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getTotalDevicesCount),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getMainAccountDevicesCount(user.account_id).pipe(
                    map((response) => DevicesActions.getTotalDevicesCountSuccess({ response })),
                    catchError((error) => of(DevicesActions.getTotalDevicesCountError({ error }))),
                ),
            ),
        ),
    );

    getDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevices, ZonesActions.getZoneByID, MapActions.initNewZone),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([, user]) =>
                this.apiService.getMainAccountDevicesCount(user.account_id).pipe(
                    tap((response) =>
                        this.store.dispatch(DevicesActions.getTotalDevicesCountSuccess({ response })),
                    ),
                    map((response) => [response.count, user]),
                ),
            ),
            exhaustMap(([count, user]) => {
                const totalCount = count as number;
                if (totalCount > 0) {
                    let totalCountArray = Array.from(Array(Math.ceil(totalCount / 100)), (x, i) => i + 1);
                    return merge(
                        ...totalCountArray.map((page) => {
                            return this.apiService
                                .getDevicesShortWithParams((user as IUser).account_id, page)
                                .pipe(
                                    map((devices) =>
                                        DevicesActions.getDevicesSuccess({ response: devices['data'] }),
                                    ),
                                    catchError((error) => of(DevicesActions.getDevicesError({ error }))),
                                );
                        }),
                    );
                } else {
                    return of(DevicesActions.getDevicesSuccess({ response: [] }));
                }
            }),
        ),
    );

    refreshDevicesWithLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesSuccess),
            withLatestFrom(
                this.store.select(selectUser),
                this.store.select(selectDevicesList),
                this.store.select(selectDevicesTotalCount),
            ),
            filter(([, , devices, totalDeviceCount]) => devices.length === totalDeviceCount),
            switchMap(([, user]) => {
                return this.apiService
                    .getDevicesLocations(user.account_id, USER_TYPES.ONLY_MASTER_ACCOUNT)
                    .pipe(
                        filter((response) => !!response.length),
                        map((response) => DevicesActions.pingDevicesLocationsSuccess({ response })),
                        catchError((error) => of(DevicesActions.pingDevicesLocationsError({ error }))),
                    );
            }),
        ),
    );

    refreshDevicesWithSOSEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesSuccess),
            withLatestFrom(
                this.store.select(selectUser),
                this.store.select(selectDevicesList),
                this.store.select(selectDevicesTotalCount),
            ),
            filter(([, , devices, totalDeviceCount]) => devices.length === totalDeviceCount),
            switchMap(([, user]) => {
                return this.apiService.getNotReadEvents(user.account_id).pipe(
                    map((response) => EventsActions.getSOSDevicesEventsSuccess({ response })),
                    catchError((error) => of(EventsActions.getSOSDevicesEventsError({ error }))),
                );
            }),
        ),
    );

    getDevicesFPIPendingFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesSuccess),
            map((devices) => devices.response),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectDevicesTotalCount)),
            filter(
                ([devices, , totalDeviceCount]) =>
                    devices.length === totalDeviceCount &&
                    devices.some((device) => device.is_overwatch_supported),
            ),
            switchMap(([devices, user]) => {
                return this.apiService
                    .getDevicesFPIPendingFieldsCount(
                        user.account_id,
                        devices
                            .filter((inDevice) => inDevice.is_overwatch_supported)
                            .map((inDevice) => inDevice.device_id),
                    )
                    .pipe(
                        map((response) =>
                            DevicesActions.getDevicesFPIPendingFieldsCountSuccess({ response }),
                        ),
                        catchError((error) =>
                            of(DevicesActions.getDevicesFPIPendingFieldsCountError({ error })),
                        ),
                    );
            }),
        ),
    );

    getDeviceByID$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceByID),
            map((action) => action.deviceID),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([deviceID, user]) => {
                let device: IDeviceFull;
                return this.apiService.getDeviceDetails(user.account_id, deviceID).pipe(
                    tap((inDevice: IDeviceFull) => (device = inDevice)),
                    mergeMap(() => this.apiService.getDeviceLocation(user.account_id, deviceID)),
                    tap((locations: string) => {
                        device = {
                            ...device,
                            location_ping: JSON.parse(locations)[0],
                        };
                    }),
                    mergeMap(() => this.apiService.getNotReadSOSEventsByDeviceID(user.account_id, deviceID)),
                    tap((event: IEventsResponse) => {
                        device = {
                            ...device,
                            allSOSEvents: event['content'],
                        };
                    }),
                    mergeMap(() => {
                        return this.apiService.getAssigedZones(user.account_id, deviceID);
                    }),
                    mergeMap((zones: IZone[]) => {
                        device.assigned_fences = Object.values(zones).flat();

                        if (device.location_ping) {
                            return this.apiService.getAddress(
                                device.location_ping.lat,
                                device.location_ping.lng,
                            );
                        } else {
                            return of(null);
                        }
                    }),
                    map((address: string) => {
                        if (address) {
                            device.location.address = address;
                        }
                        if (device.features.battery_device) {
                            this.store.dispatch(
                                DevicesActions.getDeviceSensorsDetails({ deviceID: device.info.device_id }),
                            );
                        }
                        if (device.features.overwatch) {
                            this.store.dispatch(
                                DevicesActions.getDeviceFPIDetails({
                                    accountID: user.account_id,
                                    deviceID: device.info.device_id,
                                }),
                            );
                        }

                        if (!device.active_plans.length) {
                            this.dialog.open(UpdatePlanDialogComponent, {
                                width: '800px',
                                maxHeight: '80vh',
                                backdropClass: 'dialogBackground',
                                data: {
                                    currentDeviceID: device.info.device_id,
                                },
                                disableClose: true,
                            });
                        }

                        return DevicesActions.getDeviceByIDSuccess({ device, user });
                    }),
                    catchError((error) => of(DevicesActions.getDeviceByIDError({ error }))),
                );
            }),
        ),
    );

    getDeviceDetailsByToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceDetailsByToken),
            map((action) => action.devices),
            map((devices) =>
                DevicesActions.getDeviceDetailsByTokenSuccess({
                    devices,
                }),
            ),
            catchError((error) => of(DevicesActions.getDeviceDetailsByTokenError({ error }))),
        ),
    );

    getDeviceDetailsByTokenSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.getDeviceDetailsByTokenSuccess),
                tap(({ devices }) => {
                    const bounds = new google.maps.LatLngBounds();
                    devices.forEach((device) => {
                        this.googleMapService.initDeviceMarker(device);
                        const location: ILatLng = {
                            lat: device.geoLocation.latitude,
                            lng: device.geoLocation.longitude,
                        };
                        bounds.extend(new google.maps.LatLng(location));
                    });

                    if (devices.length === 1) {
                        this.store.dispatch(DevicesActions.showDeviceInfo({ device: devices[0] }));
                    } else if (devices.length > 1) {
                        this.googleMapService.map.fitBounds(bounds);
                        this.googleMapService.map.googleMap?.setCenter(bounds.getCenter());
                    }
                }),
            ),
        { dispatch: false },
    );

    getDeviceHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceHistory),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([reqData, user, device]) =>
                this.apiService
                    .getDeviceHistory(
                        user.account_id,
                        device.info.device_id,
                        reqData.from,
                        reqData.to,
                        reqData.types,
                        reqData.rsfEnabled,
                    )
                    .pipe(
                        map((response) => DevicesActions.getDeviceHistorySuccess({ response })),
                        catchError((error) => of(DevicesActions.getDeviceHistoryError({ error }))),
                    ),
            ),
        ),
    );

    getDeviceHistoryCSVonEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceHistoryCSVonEmail),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([reqData, user, device]) =>
                this.apiService
                    .sendToEmalHistoryCSV(
                        user.account_id,
                        reqData.deviceIDs ? reqData.deviceIDs : [device.info.device_id],
                        reqData.from,
                        reqData.to,
                        reqData.types,
                        reqData.rsfEnabled,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.getDeviceHistoryCSVonEmailSuccess({
                                msg: this.translate.instant(
                                    this.localStorage.getItem('DOWNLOAD_HISTYRY') === 'FROM_WEB'
                                        ? 'CSV_HISTORY_FROM_WEB_SUCCESS_MSG'
                                        : 'CSV_HISTORY_SUCCESS_MSG',
                                ),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.getDeviceHistoryCSVonEmailError({ error }))),
                    ),
            ),
        ),
    );

    deleteDeviceHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.deleteDeviceHistory),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([reqData, user, device]) =>
                this.apiService
                    .deleteDeviceLocationHistory(
                        user.account_id,
                        device.info.device_id,
                        reqData.from,
                        reqData.to,
                    )
                    .pipe(
                        map((response) =>
                            DevicesActions.deleteDeviceHistorySuccess({
                                msg: this.translate.instant('DELETE_HISTORY_SUCCESS', {
                                    deviceId: device.info.device_id,
                                }),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.deleteDeviceHistoryError({ error }))),
                    ),
            ),
        ),
    );

    sendBeep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.sendBeep),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([peyload, user]) =>
                this.apiService.sendBeepToDevice(peyload, user.account_id).pipe(
                    map((response) => DevicesActions.sendBeepSuccess({ response })),
                    catchError((error) => of(DevicesActions.sendBeepError({ error }))),
                ),
            ),
        ),
    );

    sendBeepSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.sendBeepSuccess),
                tap(() => this.snackBar.success(this.translate.instant('BEEP_HAS_BEEN_SENT_TO_DEVICE'))),
            ),
        { dispatch: false },
    );

    setDeviceSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDeviceSettings),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) =>
                this.apiService.setDeviceSettings(user.account_id, device.info.device_id, peyload).pipe(
                    map(() => {
                        if (
                            peyload.scheduled_sleep &&
                            (device.location_ping.shutdown_reason?.includes('SHUTDOWN') ||
                                device.location_ping.device_mode_status?.includes('SHUTDOWN'))
                        ) {
                            this.dialog.open(ConfirmDialogComponent, {
                                data: {
                                    title: this.translate.instant('APPLY_CHANGES'),
                                    content: this.translate.instant('APPLY_CHANGES_CONTENT'),
                                    isHideButtons: true,
                                },
                                width: '400px',
                                backdropClass: 'dialogBackground',
                            });
                        }
                        return DevicesActions.setDeviceSettingsSuccess({
                            deviceName: peyload.device_name || device.info.nick_name,
                            preferences: peyload.preferences,
                            msg: this.translate.instant('SAVED'),
                            usageName: peyload.usage || device.info.usage,
                        });
                    }),
                    catchError(({ error }) =>
                        of(
                            StatusLabelActions.showStatusLabel({
                                statusLabel: {
                                    status: error.message_key
                                        ? this.translate.instant(error.message_key)
                                        : error.message,
                                    labelType: StatusLabelType.WARNING,
                                },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    shareDeviceLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.shareDeviceLink),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) => {
                if (!peyload.deviceIds.length) {
                    return this.apiService
                        .shareDeviceLink(user.account_id, device.info.device_id, peyload.duration)
                        .pipe(
                            map((response) => DevicesActions.shareDeviceLinkSuccess(response[0])),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                } else {
                    return this.apiService
                        .shareDevicesLink(user.account_id, peyload.duration, peyload.deviceIds)
                        .pipe(
                            map((deices) =>
                                DevicesActions.shareDeviceLinkSuccess({ response: deices } as any),
                            ),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                }
            }),
        ),
    );

    shareDeviceLinkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.shareDeviceLinkSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([response, selectedDevice]) => {
                    if (selectedDevice) {
                        this.router.navigate(
                            [`/map/devices/${selectedDevice.info.device_id}/location-info`],
                            {
                                queryParams: { token: response.token, expires: response.expires },
                            },
                        );
                    } else {
                        const devices = response['response'];
                        const tokens = [];
                        const devicesID = [];
                        let expires = null;
                        for (let key in devices) {
                            tokens.push(devices[key][devices[key].length - 1].token);
                            expires = devices[key][devices[key].length - 1].expires;
                            devicesID.push(key);
                        }
                        this.router.navigate([`map/multi-share-location-info`], {
                            queryParams: {
                                token: tokens.toString(),
                                expires,
                                devicesID: devicesID.toString(),
                            },
                        });
                    }
                }),
            ),
        { dispatch: false },
    );

    deactivateShareLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.deactivateShareLink),
            map((action) => action.devicesID),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([devicesID, user, device]) => {
                if (!devicesID.length) {
                    return this.apiService.deactivateShareLink(user.account_id, device.info.device_id).pipe(
                        map(() =>
                            DevicesActions.deactivateShareLinkSuccess({
                                msg: this.translate.instant('deleted'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.deactivateShareLinkError({ error }))),
                    );
                } else {
                    return this.apiService
                        .deactivateShareLinkForCoupleDevices(user.account_id, devicesID)
                        .pipe(
                            map(() =>
                                DevicesActions.deactivateShareLinkSuccess({
                                    msg: this.translate.instant('deleted'),
                                }),
                            ),
                            catchError((error) => of(DevicesActions.deactivateShareLinkError({ error }))),
                        );
                }
            }),
        ),
    );

    deactivateShareLinkSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.deactivateShareLinkSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([, device]) => this.router.navigate([`/map/devices/${device.info.device_id}`])),
            ),
        { dispatch: false },
    );

    updateDeviceImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateDeviceImage),
            map((action) => action.peyload),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([peyload, user, device]) => {
                if (peyload instanceof File) {
                    return this.apiService
                        .updateDeviceImage(user.account_id, device.info.device_id, peyload)
                        .pipe(
                            map((response) => DevicesActions.updateDeviceImageSuccess(response)),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                } else {
                    return this.apiService
                        .updateDeviceIcon(user.account_id, device.info.device_id, peyload)
                        .pipe(
                            map((response) => DevicesActions.updateDeviceImageSuccess(response)),
                            catchError((error) => of(DevicesActions.shareDeviceLinkError({ error }))),
                        );
                }
            }),
        ),
    );

    getDeviceContactAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactsActions.getContactsSuccess),
            filter(() => this.router.url.indexOf('share') > -1),
            combineLatestWith(this.actions$.pipe(ofType(DevicesActions.getDeviceByIDSuccess))),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([, user, device]) =>
                this.apiService.getDeviceContactAssignments(user.account_id, device.info.device_id).pipe(
                    map((response) => DevicesActions.getDeviceContactAssignmentsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDeviceContactAssignmentsError({ error }))),
                ),
            ),
        ),
    );

    triggerDevicesLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesLocations),
            withLatestFrom(
                this.store.select(selectUser),
                this.store.select(selectDevicesList),
                this.store.select(selectSectedDevice),
                this.store.select(selectSubaccountsDevices),
            ),
            mergeMap(([action, user, devices, selectedDevice, descendantsDevices]) => {
                console.log(action);

                let devicesIDs = [];
                const url = this.router.url;

                if (url === '/map/sub-accounts') {
                    devicesIDs = descendantsDevices?.map((device) => device.device_id);
                } else if (url === '/map/devices') {
                    devicesIDs = devices?.map((device) => device.device_id);
                } else if (url === '/multimap') {
                    devicesIDs = [action.deviceID];
                } else if (selectedDevice) {
                    devicesIDs = [selectedDevice.info.device_id];
                }

                return this.apiService.triggerDevicesLocations(user.account_id, devicesIDs).pipe(
                    map(() => {
                        const url = this.router.url;

                        return DevicesActions.triggerDevicesLocationsSuccess({
                            msg: this.translate.instant(url === '/multimap' ? 'UPDATED' : 'LOCATION_UPDATED'),
                        });
                    }),
                    catchError((error) =>
                        of(
                            DevicesActions.triggerDevicesLocationsError({
                                error,
                                msg: this.translate.instant('ERROR'),
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    setDeviceContactAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDeviceContactAssignments),
            map((action) => action.dataRequest),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([dataRequest, user, device]) =>
                this.apiService
                    .setDeviceContactAssignments(user.account_id, device.info.device_id, dataRequest)
                    .pipe(
                        map(() =>
                            DevicesActions.setDeviceContactAssignmentsSuccess({
                                msg: this.translate.instant('SAVED'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.setDeviceContactAssignmentsError({ error }))),
                    ),
            ),
        ),
    );

    updateDeviceImageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.updateDeviceImageSuccess, DevicesActions.setDeviceSettingsSuccess),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(() => this.googleMapService.removeMarkers()),
                tap(([, device]) => this.googleMapService.initDeviceMarker(device)),
                tap(() => this.snackBar.success(this.translate.instant('SAVED'))),
            ),
        { dispatch: false },
    );

    assignDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.assignDevice),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([zone, user, device]) =>
                this.apiService
                    .assignDevice(
                        device?.info?.owner_id ? device?.info?.owner_id : user.account_id,
                        zone.id,
                        device.info.device_id,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.assignDeviceSuccess({
                                zone,
                                msg: this.translate.instant('ASSIGNED'),
                            }),
                        ),
                        catchError(({ error }) => {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: this.translate.instant('ZONES_LIMIT_REACH'),
                                        labelType: StatusLabelType.ERROR,
                                    },
                                }),
                            );

                            return of(DevicesActions.assignDeviceError({ error }));
                        }),
                    ),
            ),
        ),
    );

    assignDeviceSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.assignDeviceSuccess),
                map((action) => action.zone),
                withLatestFrom(this.store.select(selectSectedDevice), this.store.select(selectUser)),
                filter(([, , user]) => user.preferences.show_device_zones),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(([newZone, device]) =>
                    this.googleMapService.addZones([...device.assigned_fences, newZone]),
                ),
                tap(([newZone, device]) =>
                    this.googleMapService.fitBounds([], [...device.assigned_fences, newZone], device),
                ),
            ),
        { dispatch: false },
    );

    unassignDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.unassignDevice),
            map((action) => action.zone),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([zone, user, device]) =>
                this.apiService
                    .unassignDevice(
                        device?.info?.owner_id ? device?.info?.owner_id : user.account_id,
                        zone.id,
                        device.info.device_id,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.unassignDeviceSuccess({
                                zone,
                                msg: this.translate.instant('UNASSIGNED'),
                            }),
                        ),
                        catchError((error) => of(DevicesActions.unassignDeviceError({ error }))),
                    ),
            ),
        ),
    );

    unassignDeviceSuccess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.unassignDeviceSuccess),
                withLatestFrom(this.store.select(selectSectedDevice), this.store.select(selectUser)),
                filter(([, , user]) => user.preferences.show_device_zones),
                tap(() => this.googleMapService.removeZones()),
                tap(() => this.googleMapService.removeInfoWindows()),
                tap(([, device]) => this.googleMapService.addZones(device.assigned_fences)),
                tap(([, device]) => this.googleMapService.fitBounds([], device.assigned_fences, device)),
            ),
        { dispatch: false },
    );

    getRenewalPlans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getRenewalPlans),
            map((action) => action.deviceID),
            mergeMap((deviceID) =>
                this.apiService.getRenewalPlans(deviceID).pipe(
                    map((plan) => DevicesActions.getRenewalPlansSuccess({ plan })),
                    catchError((error) => of(DevicesActions.getRenewalPlansError({ error }))),
                ),
            ),
        ),
    );

    getBrainTreeDropInToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getBrainTreeDropInToken),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .getBrainTreeDropInToken(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        payload.deviceID ? payload.deviceID : this.addDeviceService.deviceID,
                        payload.planID,
                    )
                    .pipe(
                        map((inToken) => DevicesActions.getBrainTreeDropInTokenSuccess({ token: inToken })),
                        catchError((error) => of(DevicesActions.getBrainTreeDropInTokenError({ error }))),
                    ),
            ),
        ),
    );

    activatePrepaidPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePrepaidPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService[
                    payload.paymentGateway === PAYMENT_TYPE.STRIPE
                        ? 'setDeviceToUserByStripe'
                        : 'setDeviceToUser'
                ](
                    payload.accountID ? payload.accountID : user.account_id,
                    payload.deviceID,
                    payload.planID,
                ).pipe(
                    map(() => DevicesActions.activatePrepaidPlanSuccess()),
                    catchError((error) => of(DevicesActions.activatePrepaidPlanError(error))),
                ),
            ),
        ),
    );

    activatePrepaidPlanSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePrepaidPlanSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('ADD_TRACKER_SUCCESS_MSG'),
                                labelType: StatusLabelType.SUCCESS,
                                isHideClose: true,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    renewPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPlan(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.plan,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPlanSuccess({
                                deviceID: payload.deviceID,
                                msg: 'paymentAccepted',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPlanError(error))),
                    ),
            ),
        ),
    );

    renewPrepaidPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPrepaidPlan),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPrepaidPlan(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.planID,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPrepaidPlanSuccess({
                                deviceID: payload.deviceID,
                                msg: 'SUBSCRIPTION_RENEWED',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPrepaidPlanError(error))),
                    ),
            ),
        ),
    );

    renewPlanByCoupon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPlanByCoupon),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .renewPlanByCoupon(
                        payload.masterAccountID ? payload.masterAccountID : user.account_id,
                        payload.deviceID,
                        payload.coupon,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.renewPlanByCouponSuccess({
                                deviceID: payload.deviceID,
                                msg: 'SUBSCRIPTION_RENEWED',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPlanByCouponError(error))),
                    ),
            ),
        ),
    );

    renewPlanSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.renewPlanSuccess,
                    DevicesActions.renewPrepaidPlanSuccess,
                    DevicesActions.renewPlanByCouponSuccess,
                    DevicesActions.associateDeviceWithAccountSuccess,
                ),
                withLatestFrom(this.store.select(selectSectedDevice)),
                tap(([action]) => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant(action.msg),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
                tap(([action, selectedDevice]) => {
                    if (selectedDevice) {
                        this.store.dispatch(DevicesActions.getDeviceByID({ deviceID: action.deviceID }));
                    }
                }),
            ),
        { dispatch: false },
    );

    increaseSMSLimit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.increaseSMSLimit),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            mergeMap(([action, user, device]) =>
                this.apiService
                    .activateSmsPlanBraintree(
                        action.accountID ? action.accountID : user.account_id,
                        action.deviceID ? action.deviceID : device.info.device_id,
                        action.payload,
                    )
                    .pipe(
                        map(() =>
                            DevicesActions.increaseSMSLimitSuccess({
                                smsLimit: action.smsLimit,
                                haveSelectedDevice: action.deviceID ? false : true,
                            }),
                        ),
                        catchError((error) => of(DevicesActions.increaseSMSLimitError(error))),
                    ),
            ),
        ),
    );

    increaseSMSLimitSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.increaseSMSLimitSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('paymentAccepted'),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    activatePlanByCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePlanByCard),
            map((action) => action.payload),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([payload, user]) =>
                this.apiService
                    .activatePlan(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        this.addDeviceService.deviceID,
                        payload,
                    )
                    .pipe(
                        map(() => DevicesActions.activatePlanByCardSuccess()),
                        catchError((error) => of(DevicesActions.activatePlanByCardError(error))),
                    ),
            ),
        ),
    );

    activatePlanByCardSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePlanByCardSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('paymentAccepted'),
                                labelType: StatusLabelType.SUCCESS,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    activatePlanByCoupon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.activatePlanByCoupon),
            map((action) => action.coupon),
            withLatestFrom(this.store.select(selectUser)),
            mergeMap(([coupon, user]) =>
                this.apiService
                    .activateByCoupon(
                        this.addDeviceService.SBAccountID
                            ? this.addDeviceService.SBAccountID
                            : user.account_id,
                        this.addDeviceService.deviceID,
                        coupon,
                    )
                    .pipe(
                        map(() => DevicesActions.activatePlanByCouponSuccess()),
                        catchError((error) => of(DevicesActions.activatePlanByCouponError(error))),
                    ),
            ),
        ),
    );

    activatePlanByCouponSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.activatePlanByCouponSuccess),
                tap(() => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: this.translate.instant('COUPON_SUCCESSFULLY_ACTIVATED'),
                                labelType: StatusLabelType.SUCCESS,
                                isHideClose: true,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    renewPlanError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.renewPrepaidPlanError,
                    DevicesActions.renewPlanError,
                    DevicesActions.activatePrepaidPlanError,
                    DevicesActions.activatePlanByCouponError,
                    DevicesActions.activatePlanByCardError,
                    DevicesActions.increaseSMSLimitError,
                ),
                tap(({ error }) => {
                    this.store.dispatch(
                        StatusLabelActions.showStatusLabel({
                            statusLabel: {
                                status: error['message_key']
                                    ? this.translate.instant(error['message_key'])
                                    : error.message,
                                labelType: StatusLabelType.WARNING,
                            },
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    renewPlanErrorSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.renewPlanByCouponError),
                tap(({ error }) => {
                    if (error['message_key']) {
                        return this.snackBar.error(this.translate.instant(error['message_key']));
                    } else {
                        return this.snackBar.error(error.message);
                    }
                }),
            ),
        { dispatch: false },
    );

    getAddressForDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getAddressForDevice),
            mergeMap((request) =>
                this.apiService.getAddress(request.lat, request.lng).pipe(
                    map((address) =>
                        DevicesActions.getAddressForDeviceSuccess({ address, deviceID: request.deviceID }),
                    ),
                    catchError((error) =>
                        of(
                            DevicesActions.getAddressForDeviceError({
                                error,
                                deviceID: request.deviceID,
                                errorText: this.translate.instant('UNABLE_TO_GEOCODE'),
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    setDeviceSettingsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DevicesActions.setDeviceSettingsSuccess,
                    DevicesActions.deactivateShareLinkSuccess,
                    DevicesActions.assignDeviceSuccess,
                    DevicesActions.unassignDeviceSuccess,
                    DevicesActions.deleteDeviceHistorySuccess,
                    DevicesActions.setDeviceContactAssignmentsSuccess,
                    DevicesActions.triggerDevicesLocationsSuccess,
                    DevicesActions.getDeviceHistoryCSVonEmailSuccess,
                ),
                tap((resonse) => this.snackBar.success(resonse.msg)),
            ),
        { dispatch: false },
    );

    setDeviceSettingsErrpr$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.deleteDeviceHistoryError),
                tap(({ error }) => this.snackBar.error(error.error.message_key.toUpperCase())),
            ),
        { dispatch: false },
    );

    triggerDevicesLocationsError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DevicesActions.triggerDevicesLocationsError),
                tap((resonse) => this.snackBar.error(resonse.msg)),
            ),
        { dispatch: false },
    );

    readDeviceSOSEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateDeviceEvent),
            withLatestFrom(this.store.select(selectUser), this.store.select(selectSectedDevice)),
            switchMap(([action, user, device]) => {
                const eventsIDs: number[] = action.events.map((inEvent) => +inEvent.eventId || +inEvent.id);
                return this.apiService.updateNotificationStatus(user.account_id, eventsIDs).pipe(
                    map(() => DevicesActions.updateDeviceEventSuccess({ events: action.events })),
                    catchError((error) => of(DevicesActions.updateDeviceEventError({ error }))),
                );
            }),
        ),
    );

    getSOSListOnEventUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateDeviceEventSuccess),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getNotReadEvents(user.account_id).pipe(
                    map((response) => EventsActions.getSOSDevicesEventsSuccess({ response })),
                    catchError((error) => of(EventsActions.getSOSDevicesEventsError({ error }))),
                ),
            ),
        ),
    );

    setPetDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setDevicePetDetails),
            map((action) => action.deviceUsageDTO),
            switchMap((deviceUsageDTO) =>
                this.apiService.setPetInfo(deviceUsageDTO).pipe(
                    map((response) => DevicesActions.getDevicePetDetailsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDevicePetDetailsError({ error }))),
                ),
            ),
        ),
    );

    getFPISettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceFPIDetails),
            map((action) => action),
            switchMap((action) =>
                this.apiService.getFPISettings(action.accountID, action.deviceID).pipe(
                    map((response) => DevicesActions.getDeviceFPIDetailsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDeviceFPIDetailsError({ error }))),
                ),
            ),
        ),
    );

    setFPIDetailsSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.setFPIDetailsSettings),
            map((action) => action),
            switchMap((action) => {
                return this.apiService
                    .updateFPISettings(action.accountID, action.deviceID, action.settings)
                    .pipe(
                        map((response) =>
                            DevicesActions.setFPIDetailsSettingsSuccess({ response: action.settings }),
                        ),
                        catchError((error) => of(DevicesActions.setFPIDetailsSettingsError({ error }))),
                    );
            }),
        ),
    );

    updateFPIUsageSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.updateFPIUsageSettings),
            map((action) => action),
            switchMap((action) => {
                return this.apiService
                    .updateFPIUsageFromSettings(action.accountID, action.deviceID, action.payload)
                    .pipe(
                        map((response) => DevicesActions.updateFPIUsageSuccess({ response })),
                        catchError((error) => of(DevicesActions.updateFPIUsageError({ error }))),
                    );
            }),
        ),
    );

    getPetActivityInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getPetActivityInfo),
            withLatestFrom(this.store.select(selectSectedDevice)),
            filter(([, device]) => device && device?.info?.usage.toUpperCase() === 'PET'),
            debounceTime(500),
            distinctUntilChanged(),
            mergeMap(([action, device]) => {
                const startDate = action['startDate']
                    ? action['startDate']
                    : +new Date(moment(new Date().setHours(0, 0, 0, 0)).unix()) * 1000;
                const endDate = action['endDate']
                    ? action['endDate']
                    : +new Date(moment(new Date().setHours(23, 59, 59, 599)).unix() * 1000);

                return this.apiService
                    .getActivityDataByDate(device?.info?.device_id, startDate, endDate)
                    .pipe(
                        map((response) => DevicesActions.getPetActivityInfoSuccess({ response })),
                        catchError((error) => of(DevicesActions.getPetActivityInfoError({ error }))),
                    );
            }),
        ),
    );

    getDeviceSensorsDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDeviceSensorsDetails),
            map((action) => action.deviceID),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([deviceID, user]) =>
                this.apiService.getDeviceSensorsDetails(user.account_id, deviceID).pipe(
                    map((response) => DevicesActions.getDeviceSensorsDetailsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getDeviceSensorsDetailsError({ error }))),
                ),
            ),
        ),
    );

    associateDeviceWithAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.associateDeviceWithAccount),
            map((action) => action),
            mergeMap((action) => {
                return this.apiService
                    .associateDeviceWithAccount(action.account_id, action.device_id, action.plan_id)
                    .pipe(
                        map(() =>
                            DevicesActions.associateDeviceWithAccountSuccess({
                                deviceID: action.device_id,
                                msg: 'paymentAccepted',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.associateDeviceWithAccountError(error))),
                    );
            }),
        ),
    );

    renewPlanWithStripe$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.renewPlanWithStripe),
            map((action) => action),
            switchMap((action) => {
                return this.apiService
                    .renewPlanWithStripe(action.account_id, action.device_id, action.plan_id)
                    .pipe(
                        map(() =>
                            DevicesActions.renewPlanWithStripeSuccess({
                                deviceID: action.device_id,
                                msg: 'SUBSCRIPTION_RENEWED',
                            }),
                        ),
                        catchError((error) => of(DevicesActions.renewPlanWithStripeError(error))),
                    );
            }),
        ),
    );

    getDevicesForFOTA$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesForFOTA),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getDevicesFWStatus(user.account_id).pipe(
                    map((response) =>
                        DevicesActions.getDevicesForFOTASuccess({ response: response['data'] }),
                    ),
                    catchError((error) => of(DevicesActions.getDevicesForFOTAError({ error }))),
                ),
            ),
        ),
    );

    getFotaFOTAlogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DevicesActions.getDevicesForFOTASuccess, DevicesActions.getFOTALogs),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([, user]) =>
                this.apiService.getFotaLogs(user.account_id).pipe(
                    map((response) => DevicesActions.getFOTALogsSuccess({ response })),
                    catchError((error) => of(DevicesActions.getFOTALogsError({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private snackBar: SnackbarService,
        private router: Router,
        private googleMapService: GoogleMapService,
        private translate: TranslateService,
        private addDeviceService: AddDeviceService,
        private soundService: SoundService,
        private localStorage: LocalStorageService,
        public dialog: MatDialog,
    ) {}
}
