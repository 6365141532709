import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/store/services';

@Component({
    selector: 'app-lost-pets-home-modal',
    templateUrl: './lost-pets-home-modal.component.html',
    styleUrls: ['./lost-pets-home-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPetsHomeModalComponent implements OnInit {
    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<LostPetsHomeModalComponent>,
        private localStorage: LocalStorageService,
    ) {}

    ngOnInit(): void {}

    closeDialog(): void {
        this.dialogRef.close();
        this.localStorage.setItem('LostPetsHomeModalWasShown', 'true');
    }

    goToJoined() {
        this.dialogRef.close();
        this.router.navigate(['/map/rings/community-types']);
    }
}
