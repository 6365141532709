<section class="container">

    <app-header></app-header>

    <div class="lost-pet" *ngIf="petInfo; else loader">
        <h1>{{ 'PET_LOST' | translate: { pet_name: petInfo.petName } }}</h1>

        <div class="img-box">
            <img src="{{petInfo.petIcon}}" alt="{{petInfo.petName}}" />

            <div class="three-img">
                <img (click)="setImageAsMain(image)" *ngFor="let image of petInfo.images" src="{{image}}" />
            </div>
        </div>

        <div class="details-block">
            <div class="left-text">{{ 'AREA_LAST_SEEN' | translate }}</div>
            <div class="right-text">
                <span class="text">{{ address$ | async }}</span>

                <div class="icon" (click)="goToMap()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.18992 4.82273C8.45579 3.55686 10.1727 2.8457 11.9629 2.8457C13.7531 2.8457 15.47 3.55686 16.7359 4.82273C18.0017 6.0886 18.7129 7.80549 18.7129 9.5957C18.7129 12.7007 16.979 15.5279 15.1469 17.6348C14.2401 18.6777 13.3313 19.5186 12.649 20.0985C12.3792 20.3278 12.1457 20.5156 11.9629 20.6582C11.7801 20.5156 11.5466 20.3278 11.2768 20.0985C10.5945 19.5186 9.68572 18.6777 8.77884 17.6348C6.94678 15.5279 5.21289 12.7007 5.21289 9.5957C5.21289 7.80549 5.92405 6.0886 7.18992 4.82273ZM11.5323 22.2098C11.5326 22.21 11.5328 22.2101 11.9629 21.5957L11.5328 22.2101C11.791 22.3909 12.1347 22.3909 12.393 22.2101L11.9629 21.5957C12.393 22.2101 12.3932 22.21 12.3934 22.2098L12.3941 22.2094L12.3958 22.2081L12.4014 22.2042L12.4204 22.1907C12.4366 22.1791 12.4598 22.1624 12.4893 22.1409C12.5485 22.0977 12.6334 22.0347 12.7406 21.9528C12.9548 21.7891 13.258 21.5495 13.6205 21.2414C14.3445 20.626 15.3107 19.7324 16.2788 18.6191C18.1968 16.4135 20.2129 13.2407 20.2129 9.5957C20.2129 7.40767 19.3437 5.30925 17.7965 3.76207C16.2493 2.2149 14.1509 1.3457 11.9629 1.3457C9.77485 1.3457 7.67643 2.2149 6.12926 3.76207C4.58208 5.30925 3.71289 7.40767 3.71289 9.5957C3.71289 13.2407 5.72901 16.4135 7.64694 18.6191C8.61506 19.7324 9.58127 20.626 10.3053 21.2414C10.6678 21.5495 10.971 21.7891 11.1852 21.9528C11.2923 22.0347 11.3773 22.0977 11.4365 22.1409C11.466 22.1624 11.4891 22.1791 11.5054 22.1907L11.5244 22.2042L11.53 22.2081L11.5317 22.2094L11.5323 22.2098ZM9.71289 9.5957C9.71289 8.35306 10.7203 7.3457 11.9629 7.3457C13.2055 7.3457 14.2129 8.35306 14.2129 9.5957C14.2129 10.8383 13.2055 11.8457 11.9629 11.8457C10.7203 11.8457 9.71289 10.8383 9.71289 9.5957ZM11.9629 5.8457C9.89182 5.8457 8.21289 7.52464 8.21289 9.5957C8.21289 11.6668 9.89182 13.3457 11.9629 13.3457C14.034 13.3457 15.7129 11.6668 15.7129 9.5957C15.7129 7.52464 14.034 5.8457 11.9629 5.8457Z"
                            fill="#009C56" />
                    </svg>
                </div>

            </div>
        </div>

        <div class="details-block">
            <div class="left-text">{{ 'DATE_LAST_SEEN' | translate }}</div>
            <div class="right-text">{{ petInfo.location.created * 1000 | dateFormat: 'HH:mm a' }}</div>
        </div>


        <div class="details-block" *ngIf="petInfo.details.gender">
            <div class="left-text">{{ 'GENDER' | translate }}</div>
            <div class="right-text">{{ petInfo.details.gender | lowercase }}</div>
        </div>

        <div class="details-block" *ngIf="petInfo.details.breed">
            <div class="left-text">{{ 'BREED' | translate }}</div>
            <div class="right-text">{{ petInfo.details.breed }}</div>
        </div>



        <!-- <div class="details-block">
            <div class="left-text">More details</div>
            <div class="right-text">
                Gender, breed, allergy...
                <span class="ml-8-px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M12 7.5C11.9931 7.75648 11.904 7.97632 11.7189 8.17418L6.38492 13.7508C6.22723 13.9121 6.04212 14 5.81587 14C5.35651 14 5 13.6189 5 13.128C5 12.8935 5.08913 12.6736 5.24682 12.5051L10.046 7.5L5.24682 2.49493C5.08913 2.32638 5 2.11387 5 1.87204C5 1.38106 5.35651 1 5.81587 1C6.03526 1 6.22723 1.08794 6.38492 1.24915L11.7189 6.83315C11.9109 7.02368 12 7.24352 12 7.5Z"
                            fill="#4F4F4F" />
                    </svg>
                </span>
            </div>
        </div> -->


        <div class="btn-container" *ngIf="isShowJoinCommunity">

            <a href="{{skin.storeLink}}" target="_blank" class="btn outline-bordered" mat-flat-button>
                {{ skin.whiteLabel === 'TAGANDTRACK' ? 'JOIN_TP_COMMUNITY' : ' Join PetLoc8 community' | translate }}
            </a>

            <button class="btn primary" (click)="getOwnerPhone()" [matMenuTriggerFor]="menu" mat-flat-button>
                {{ 'CALL_OWNER' | translate }}
            </button>
            <mat-menu class="owner-phone-menu" #menu="matMenu" yPosition="above">
                <button class="owner-phone" (click)="callToOwner()" mat-menu-item>{{ownerPhone ? ownerPhone :
                    ('LOADING' | translate) }}</button>
            </mat-menu>
        </div>

        <ng-container *ngIf="!isShowJoinCommunity">
            <button class="btn primary" (click)="helpWithSearch()" *ngIf="!isShowCheckMmark" mat-flat-button>
                {{ 'HELP_WITH_SEARCH' | translate }}
            </button>

            <div class="text-center checkmark" *ngIf="isShowCheckMmark">
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.2" y="1.2" width="49.6" height="49.6" rx="24.8" stroke="#009C56" stroke-width="2.4" />
                    <path
                        d="M33.1153 19.2193C33.6513 18.6699 34.5312 18.659 35.0806 19.1949C35.6301 19.7309 35.641 20.6108 35.1051 21.1603L23.7978 32.7521C23.2491 33.3145 22.3436 33.3107 21.7998 32.7436L16.8867 27.6206C16.3554 27.0666 16.3738 26.1868 16.9278 25.6555C17.4818 25.1242 18.3616 25.1426 18.8929 25.6966L22.8114 29.7825L33.1153 19.2193Z"
                        fill="#009C56" />
                </svg>
            </div>
        </ng-container>

    </div>

    <ng-template #loader>
        <mat-progress-bar class="progress" mode="indeterminate"></mat-progress-bar>
    </ng-template>

    <app-footer class="footer"></app-footer>

</section>