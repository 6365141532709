import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { skin } from 'src/white-labels';
import { ILogin, IStatusLabel } from 'src/app/store/interfaces';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { selectIsLoginProcessing, selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EMAIL_VALIDATOR_REG_EXP } from 'src/app/store/constants/common.constants';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { ApiService, LocalStorageService, WindowService } from '../../store/services';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EncryptionService } from 'src/app/store/services/auth/auth-encryption-algorithm.service';
import { AuthService } from 'src/app/store/services/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    loginError$: Observable<IStatusLabel>;
    loginProcessing$: Observable<boolean>;
    form: UntypedFormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
        password: ['', [Validators.required]],
        isKeepLogged: [true],
    });
    showPassword = false;
    showPasswordField = false;
    skin = skin;
    googleUserData: any;
    private destroyed$ = new Subject<void>();

    constructor(
        public localStorage: LocalStorageService,
        private formBuilder: UntypedFormBuilder,
        private store: Store<IAppState>,
        private route: ActivatedRoute,
        private encryptionService: EncryptionService,
        private apiService: ApiService,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private router: Router,
        private window: WindowService,
    ) {
        // TODO - remove when PETLOC8 will be done
        if (skin.whiteLabel === 'PETLOC8') {
            this.window.getWindow.location.href = skin.storeLink;
        }
    }

    ngOnInit() {
        console.log('Radhika test');
        this.loginError$ = this.store.select(selectIsStatusLabel);
        this.loginProcessing$ = this.store.select(selectIsLoginProcessing);

        this.route.queryParams.subscribe((params) => {
            if (params.info) {
                const decodedToken = atob(params.info);
                if (this.localStorage.getItem('token')) {
                    this.localStorage.remove('token');
                }
                this.localStorage.setItem('token', `Bearer ${decodedToken}`);
                this.router.navigate(['/map']);
            }
        });
    }

    ngOnDestroy(): void {
        this.store.dispatch(StatusLabelActions.hideStatusLabel());
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    changeVisiblePassword(): void {
        this.showPassword = !this.showPassword;
    }

    onSubmit(isValid: boolean) {
        if (this.form.controls['email'].valid && !this.showPasswordField) {
            this.apiService
                .emailCheck({
                    email: this.encryptionService.encrypt(this.form.value.email.trim(), skin.whiteLabel),
                    whitelabel: skin.whiteLabel,
                })
                .pipe(takeUntil(this.destroyed$))
                .subscribe((res) => {
                    this.authService.lastLoginUserInfo = {
                        ...res,
                        provider: res.loginWith,
                    };

                    if (!res.userExist) {
                        this.router.navigate(['/create-account']);
                        this.router.navigate(['/create-account'], {
                            queryParams: { email: this.form.value.email.trim() },
                        });
                        return;
                    }
                    if (res.loginWith === 'PASSWORD') {
                        this.showPasswordField = true;
                    } else {
                        this.router.navigate(['/welcome-back']);
                    }
                    this.cdRef.markForCheck();
                    setTimeout(() => {
                        this.form.controls['password'].setErrors(null);
                    }, 10);
                });
        }

        if (isValid) {
            const userData: ILogin = {
                username: this.form.value.email.trim(),
                password: this.form.value.password.trim(),
                remember_me: this.form.value.isKeepLogged,
                whitelabel: skin.whiteLabel,
            };
            this.store.dispatch(StatusLabelActions.hideStatusLabel());
            this.store.dispatch(AuthActions.login({ userData }));
        }
    }
}
