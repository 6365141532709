<div class="dialog-wrapper">
    <div mat-dialog-title>
        <h1 class="title">
            {{'HELP_BRING_LOST_PET' | translate}}
            <i class="icon close-icon" [inlineSVG]="'#close'" (click)="closeDialog()"></i>
        </h1>

    </div>
    <div mat-dialog-content>
        <h5>{{'ENTER_YOUR_ADDRESS' | translate}}</h5>
        <img src="assets/images/rings/sleeping-baby.svg" alt="">
    </div>
    <div mat-dialog-actions>
        <div class="dialog-actions">
            <button class="btn primary" (click)="goToJoined()" mat-flat-button>
                {{ 'ADD_LOCATION' | translate }}
            </button>

            <button class="btn outline" (click)="closeDialog()" mat-flat-button>
                {{ "DONT_ASK_ME_AGAIN" | translate }}
            </button>
        </div>
    </div>
</div>